.main-navigation {
    background-color: $primary-color;
    display: none;
    min-height: 45px;
    z-index: 10;
    position: relative;

    .catalog-filter {
        .catalog-filter-tree {
            margin: 0;

            a {
                &.cat-item {
                    border: none;

                    span {
                        vertical-align: text-top;
                    }
                }
            }

            & > ul {
                display: block;
                margin-bottom: 0;
                padding: 0;

                & > li {
                    font-size: 16px;
                    text-transform: uppercase;
                    font-weight: 200;
                    display: block;
                    margin-left: 0;
                    margin-right: 0px;
                    padding: 0;

                    a {
                        color: $white;
                        text-decoration: none;
                        transition: ease all .1s;
                        padding: 10px 12px;
                    }
                                        &.active,
                    &:hover {
                        color: $white;
                        background-color: darken($primary-color, 10%);

                        & > ul {
                            opacity: 1;
                            visibility: visible;
                        }
                    }

                    & > ul {
                        opacity: 0;
                        transition: ease all .2s;
                        visibility: hidden;
                        position: absolute;
                        background-color: $white;
                        z-index: 999;
                        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
                        border: 1px solid $gray;
                        border-top: none;
                        padding: .5rem;

                        li {
                            a {
                                color: $dark-gray;
                                text-transform: initial;
                                padding: 5px 20px;

                                &:hover {
                                    background: inherit;
                                    color: darken($primary-color, 10%);
                                }
                            }
                        }
                    }

                    @include tablet {
                        margin-left: auto;
                        display: inline-block;
                    }
                }
            }
        }
    }

    @include tablet {
        display: block;
    }
}

#menu {
    &.catalog-filter-tree {
        ul {
            @include mobile {
                padding-left: 0;
            }
        }
    }
}


// META NAVIGATION
ul.meta-navigation {
    padding-left: 0;
    list-style: none;
    text-align: center;
    display: none;
    margin-top: 5px;
    margin-bottom: 5px;
    position: relative;

    .meta-navigation--item {
        font-weight: 200;
        font-size: 1rem;
        display: inline-block;

        span {
            display:inline-block;
        }

        &.active {
            background-color: $white;

            span {
                display:inline-block;
                border-bottom:2px solid $secondary-color;
            }
        }

        &.admin {
            display: flex;
            justify-content: center;
            align-items: center;
            svg {
                margin-right: 5px;
            }
        }

        &.logout-block {
            margin-right: 60px;
        }

        .logout {
            background-color: $primary-color;
            border-radius: 20px;
            padding: 1px 12px;
            color: $white;
            font-size: 12px;
            margin: 0 5px;
            font-weight: $font-regular;

            &:hover {
                color: $white;
            }
        }


        a {
            padding: 10px 5px;
            display: block;
            text-decoration: none;
            color: $dark-gray;
            position: relative;

            .warenkorb {
                margin-left: 5px;
            }

            &:hover {
                color: $primary-color;
            }
        }

        .meta-navigaton--basket {
            position: absolute;
            right: -10px;
            top: 0;
            background-color: $danger-red;
            border-radius: 50%;
            width: 15px;
            height: 15px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: $white;
            font-size: 12px;
            font-weight: $font-bold;

            @include mobile {
                right: 15px;
                top: 50%;
                transform: translateY(-50%);
            }
        }
    }

    @include tablet {
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }
}


// MOBILE NAVIGATION
#menu.mm-menu_opened {
    & ~ .header {
        .mobile-menu--button {
            span:first-child {
                top: 50%;
                transform: rotate(45deg);
                position: absolute;
            }

            span:nth-child(2) {
                opacity: 0;
            }

            span:last-child {
                transform: rotate(-45deg);
                top: 50%;
            }
        }
    }

    .cart {
        &.mm-listitem {
            .mm-listitem__text {

                .meta-navigaton--basket {
                    position: absolute;
                    right: -10px;
                    top: 0;
                    background-color: $danger-red;
                    border-radius: 50%;
                    width: 15px;
                    height: 15px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: $white;
                    font-size: 12px;
                    font-weight: $font-bold;

                    @include mobile {
                        right: 15px;
                        top: 50%;
                        transform: translateY(-50%);
                    }
                }
            }
        }
    }

    .mm-panels {
        & > div {
            background: $white;

            .mm-navbar {
                background: $white;
            }

            ul {
                li {
                    a {
                        &.cat-item {
                            border: none;
                            padding: 12px 10px 12px 20px;
                        }
                    }
                }
            }
        }
    }
}


// MOBILE MENU BUTTON
.logo {
    margin: 20px 0;

    .mobile-menu--button {
        height: 22px;
        width: 30px;
        position: relative;
        display: inline-block;

        span:first-child {
            height: 2px;
            width: 30px;
            background: darken($primary-color, 10%);
            display: block;
            transition: transform .2s, top .3s ease;
        }

        span:nth-child(2) {
            position: absolute;
            top: 50%;
            width: 30px;
            height: 2px;
            opacity: 1;
            background: darken($primary-color, 10%);
            transform: translateY(-50%) translateX(0);
            transition: opacity .2s ease .1s;
        }

        span:last-child {
            position: absolute;
            bottom: 0;
            width: 30px;
            height: 2px;
            background: darken($primary-color, 10%);
            transition: transform .4s, top .3s ease;
        }

        @include tablet {
            display: none;
        }
    }

    @include desktop {
        margin: 0;
    }

    @include tablet {
        justify-content: left;
        align-items: flex-start;
    }

    @media (min-width: 768px) and (max-width: 991px) {
        margin: 30px 0;

        .col-md-12 {
            position: absolute;
            left: 0;
            top: 5px;
        }
    }
}

.header-logo{
    @include mobile{
        padding: 15px;
        margin-bottom: 15px;
    }
    .mobile-menu--button {
        height: 22px;
        width: 30px;
        position: absolute;
        display: inline-block;
        top: 50%;
        transform: translateY(-50%);

        span:first-child {
            height: 2px;
            width: 30px;
            background: darken($primary-color, 10%);
            display: block;
            transition: transform .2s, top .3s ease;
        }

        span:nth-child(2) {
            position: absolute;
            top: 50%;
            width: 30px;
            height: 2px;
            opacity: 1;
            background: darken($primary-color, 10%);
            transform: translateY(-50%) translateX(0);
            transition: opacity .2s ease .1s;
        }

        span:last-child {
            position: absolute;
            bottom: 0;
            width: 30px;
            height: 2px;
            background: darken($primary-color, 10%);
            transition: transform .4s, top .3s ease;
        }

        @include tablet {
            display: none;
        }
    }
}


