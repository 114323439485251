.announcement{
  &.note-open{
    display: block;
    animation: flash-show 300ms ease 0s;
  }
  &.note-closed{
    animation: flash-hide 300ms ease 0s;
    display: none;
  }
  height: 75px;
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 10;
  background-color: #ffffff;
  box-shadow: 0 0 30px 2px darken(#E2E2E2, 2);
  .note-box{
    margin: 0 50px 0 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    .text {
      display: inline-block;
      line-height: 75px;
      vertical-align: top;
      font-size: .9em;
      font-weight: 300;
      color: transparentize(#191919, 0.25);
      padding-left: 25px;
    }
  }
}

@keyframes flash-hide{
  0%{
    right: 20px;
    opacity: 1;
  }
  100%{
    right: -100%;
    opacity: 0;
  }
}

@keyframes flash-show{
  0%{
    right: -100%;
    opacity: 0;
  }

  100%{
    right: 20px;
    opacity: 1;
  }

}

