.a-left,
.a-right {
    background-color: transparent;
}

.a-left {
    &:after {
        content: "";
        @extend .arrow-left-black--icon;
    }
}

.a-right {
    &:after {
        content: "";
        @extend .arrow-right-black--icon;
    }
}


.brand-slider {
    margin-top: 30px;
    background: $white;
    border-radius: 20px;

    .headline-wrapper {
        display: flex;
        align-items: center;
        padding-left: 14px;

        h2.headline {
            color: $black;
            display: inline-block;
            text-transform: none !important;
            position: relative;
            transform: translate(0);
            top: 0;
            left: 0;
            padding: 0;
        }
    }

    .slick-track {
        padding-bottom: 80px;
    }
}

.slider {
    padding-top: 15px;
}

#product-slider {
    padding-bottom: 30px;
    .a-left,
    .a-right {
        top: 25%;
        transform: translateY(-25%);
    }
    .offer{
        display: none;
    }
}

.top-products {
    width: 100%;
    margin-top: 30px;
    background: $white;
    @include rounded;

    .headline-wrapper {
        display: flex;
        align-items: center;
        padding: 19px 14px;

        h2.headline {
            color: $black;
            display: inline-block;
            text-transform: none !important;
            position: relative;
            transform: translate(0);
            top: 0;
            left: 0;
            padding: 0;
        }
    }

    .slick-slide {
        margin: 0 15px;

        a {
            @include rounded;
        }

        .product {
            border-bottom: 0;
            text-align: center;

            .price-list {
                padding: 15px 0;
                background-color: $primary-color;
                width: 100%;
                color: $white;
                font-size: 18px;
                height: 33px;
                text-align: center;

                &:hover {
                    .price-text {
                        margin-top: -26px;
                    }
                }

                .price-actual {
                    height: 26px;
                    overflow: hidden;

                    .price-text {
                        transition: all .3s ease;
                        padding-top: 2px;

                        &.isloading {
                            display: none;
                        }
                    }

                    .calc-icon {
                        display: none;
                    }

                    .ordernow__button-text.isloading,
                    .cost.isloading {
                        display: none;
                    }
                }
            }

            .text-list {
                margin: 2em auto 1em auto;
                height: 90px;
                display: flex;
                align-items: center;
                justify-content: center;

                h2 {
                    font-size: 19px;
                    text-transform: inherit;
                    height: auto;
                    color: $dark-gray;
                    line-height: 23px;
                    margin-bottom: 0;
                }

                .text-item {
                    font-size: 18px;
                    height: auto;
                }
            }

            .offer {
                margin-top: 15px;
            }
        }
    }
}

.slick-dots {
    list-style: none;
    text-align: center;
    margin: 15px 0;
    padding: 0;
    li {
        display: inline-block;
        margin-right: 5px;

        &.slick-active {
            button {
                content: '';
                border-radius: 50%;
                background-color: $black;
                display: inline-block;
                width: 15px;
                height: 15px;
                padding: 0;
                color: $black;
                border: 1px solid $black;
                font-size: 0;
            }
        }

        button {
            content: '';
            border-radius: 50%;
            width: 15px;
            height: 15px;
            padding: 0;
            background-color: $white;
            border: 1px solid $black;
            display: inline-block;
            color: $white;
            font-size: 0;
        }
    }
}

#homepage-slider-mobile,
#homepage-slider{
    @include rounded;
    .slick-slide{
        img{
            @include rounded;
        }
    }
}

.catalog-detail{
    .catalog-detail-image{
        .bu-image-single,
        .image-single{
            max-width: 100%;
            height: 100%;
            #details-image-slider{
                padding-top: 50px;
                padding-bottom: 15px;
                .item{
                    max-width: 100%;
                }
            }
        }
    }
    #similar-products-slider{
        padding: 20px;
        .product{
            .price-list{
                padding: 5px 0;
                .price-text{
                    padding-top: 2px;
                    text-align: center;
                }
                &:hover{
                    .price-text{
                        margin-top: -25px;
                    }
                }
                .ordernow__button-text{
                    text-align: center;
                }
            }
            .text-list{
                max-width: 240px;
            }
            .offer {
                margin-top: 15px;
                border-radius: 15px;
                overflow: hidden;
            }
        }
    }
}
.eleasa-speaker{
    padding-bottom: 30px;
    .similar-products-text{
        display: flex;
        justify-content: center;
        align-items: center;
        .header{
            text-transform: unset;
            border: 0 none;
            font-weight: bold;
            font-size: 25px;
        }
    }
    .eleasakopf{
        width: 200px;
        height: 200px;
        margin-left: 60px;
        margin-bottom: -24px;
        @include mobile{
            display: block;
            margin: 0 auto;
        }
    }
}

