.faq {
  .faq-header {
    .topic {
      background: $white;
      border-radius: 5px;
      padding: 5px 25px;
      margin-right: 5px;
      cursor: pointer;
      min-width: 140px;
      text-align: center;
      transition: ease all .3s;
      width: 100%;
      margin-bottom: 15px;

      &.active,
      &:hover {
        color: $white;
        background: $primary-color;
      }

      @include desktop {
        width: auto;
      }
    }
  }

  .faq-body {
    border-top: 1px solid #dbdbdb;
    padding-top: 15px;
    margin-top: 0;

    .faq-item {
      background: $white;
      padding: 10px 15px;
      border-radius: 5px;
    }

    @include desktop {
      border: none;
      padding-top: 0;
    }
  }
}

.faq-content {
  position: relative;
  margin-bottom: 50px;
  padding-top: 0;

  @include desktop {
    padding-top: 100px;
    margin-bottom: 0;
  }

  .eleasageht {
    max-height: 100%;
    height: 500px;
    @include mobile {
      position: relative;
      margin: 0 auto;
      max-width: 35%;
      display: block;
      height: 250px;
    }
    @include tablet {
      max-width: 35%;
    }
    @include desktop {
      max-width: 65%;
    }
    @media(min-width: 992px) and (max-width: 1199px) {
      max-width: 35%;
    }
  }

  .eleasa-speech-bubble {
    padding: 10px 20px;
    border-radius: 5px;
    display: inline-block;
    width: 55%;
    position: absolute;
    font-size: 18px;
    @include mobile {
      position: relative;
      font-size: 12px;
      display: block;
      margin: 0 auto;
    }
    @include tablet {
      top: 10%;
      right: 2%;
      margin: -28px 30px;
    }
    @include desktop {
      top: 4%;
      right: -4%;
    }
    @media(min-width: 1024px) and (max-width: 1199px) {
      right: -2%;
    }

    &.left {
      &:after {
        top: 119px;
        left: -28px;
        border-left: 15px solid transparent;
        border-right: 15px solid $portal-background-gray;
        border-top: 15px solid transparent;
        border-bottom: 15px solid $portal-background-gray;
        @include mobile {
          border-left: 20px solid transparent;
          border-right: 20px solid transparent;
          border-top: 20px solid transparent;
          border-bottom: 20px solid $portal-background-gray;
          left: 50%;
          top: -39px;
        }
      }

      &:before {
        top: 120px;
        left: -31px;
        border-left: 15px solid transparent;
        border-right: 15px solid $black;
        border-top: 15px solid transparent;
        border-bottom: 15px solid $black;
        @include mobile {
          left: 50%;
          top: -40px;
          border-left: 20px solid transparent;
          border-right: 20px solid transparent;
          border-top: 20px solid transparent;
          border-bottom: 20px solid $black;
        }
      }
    }
  }

  .content_two {
    padding-left: 21px;
    @include mobile {
      padding: 0;
    }
  }

  .content_three {
    padding-left: 0;
    @include mobile {
      padding: 0;
    }
  }

  .content_six {
    padding-left: 20px;
    @include mobile {
      padding: 0;
    }
  }

  .content_seven {
    padding-left: 70px;
    @include mobile {
      padding: 0;
    }
  }

  span {
    display: block;
    font-family: "Bradford Medium", sans-serif;
  }
}

