.service {
    margin: 50px 0;

    .btn {
        width: 15.5em;
    }

    p {
        margin-bottom: 5px;
    }

    .info-box {
        margin: 0;
        height: 100%;

        .button-row {
            position: absolute;
            bottom: 15px;
            margin: 0 -10px;
            width: calc(100% - 12px);
            display: flex;
            flex-wrap: wrap;

            .col-6, .col-lg-6 {
                padding: 0 10px;
            }

            .btn {
                flex: 0 0 50%;
                margin: 0 0;
                white-space: nowrap;
                width: 100%;
                overflow: hidden;
                min-width: auto;
                text-overflow: ellipsis;
            }
        }

        .info-box--body p {
            margin-bottom: 10px;
        }
    }

    .service-box {
        height: 100%;
        padding: 0 0 20px 0;
    }

    .service-intro {
        margin-bottom: 40px;

        .service-intro--image {
            text-align: center;

            svg {
                margin: 50px 0;
            }
        }
    }

    .service-form {

        h3 {
            padding-bottom: 10px;
        }

        .upload-container{
            margin-top: 40px;

            .upload-btn {
                padding: 4px 30px;
                font-size: 17px;
                margin-top: 8px;
                margin-right: 20px;
                display: inline-block;

                &:hover {
                    cursor: pointer;
                }
            }

            .file-list {
                display: inline-block;
                .file-name {
                    font-size: 17px;
                    color: $dark-gray-font;
                    margin-right: 15px;

                    .loeschen {
                        width: 25px;
                        height: 25px;

                    }
                }
            }

            .file-format-note {
                font-size: 12px;
                display: block;
            }
        }


        .service-form-item {
            margin-top: 20px;

            label {
                font-weight: $font-bold;
            }

            select,
            .form-control {
                margin-top: 0;
            }

            textarea {
                display: block;
                width: 100%;
                border: 1px solid $light-gray;
                padding: 10px 15px;
                border-radius: 0;
                box-shadow: none;
                transition: ease all .3s;

                @include pseudos {
                    border-color: #8D8D8D;
                    box-shadow: none;
                    outline: 0;
                }
            }
        }

        .common-summary-detail {
            padding: 15px 0;
            margin-bottom: 80px;
            margin-top: -10px;

            .basket {
                margin: 0;

                .product-row {
                    align-items: center;
                    border-bottom: 1px solid $light-gray;
                    padding-bottom: 15px;

                    p {
                        margin-bottom: 0;
                    }

                    img {
                        float: left;
                        max-width: 6em;
                        max-height: 8em;
                        overflow: hidden;
                        margin-right: 5%;
                    }

                    .details-product {
                        display: flex;
                        align-items: center;
                    }

                    @include mobile {
                        img {
                            width: 100%;
                            max-height: 100%;
                        }
                    }
                }
            }
        }

        .service-description {
            margin-bottom: 80px;
        }

        .service-contact {
            .contact-item {
                display: block;
            }
        }

    }

    .service-additonal {
        margin-top: 50px;

        .info-container{
            margin-bottom: 20px;
        }
        .info-box {

            ul {
                padding-left: 20px;
            }
        }

        .btn-primary,
        .btn-primary--bordered {
            width: 100%;
        }

        .btn-footer {
            width: 15.5em;
        }

        .note {
            margin-top: 50px;
        }

        @include mobile {
            .btn {
                width: 100%;
                margin: 5px 0;
                text-align: center !important;
            }
        }
    }

    &.home {
        .btn-primary,
        .btn-primary--bordered {
            width: auto;
            min-width: 15em;
            margin: 25px 25px 0 0;

            @include mobile {
                min-width: 100%;
                margin-right: 0;
            }
        }
    }

    &.service-summary {
        h3 {
            border-bottom: 1px solid $light-gray;
        }
    }

    .ueberblick {
        margin-right: 5px;
    }

    .note {
        margin-top: 100px;
    }

    .service-subheadline {
        margin-top: 20px;
        @include tablet {
            margin-top: 100px;
            margin-bottom: 30px;
        }
    }

}

.service-intro--content {

    &.service-summary {
        h1 {
            margin-bottom: 10px;
            font-weight: $font-regular;
            text-transform: none;
        }
    }

    h1 {
        @include tablet {
            margin-bottom: 50px;
        }
    }
}

.service-form {
    margin-bottom: 60px;

    input {
        border: 1px solid $gray-border;
        width: 100%;
        padding: 0 5px;

        &[type="radio"] {
            width: 15px;
        }
    }

    label {
        font-weight: $font-bold;
    }

    .customer-service-items {
        margin-bottom: 55px;
        font-size: 17px;
    }
}

.customer-service-item {
    display: block;
    padding-bottom: 3px;
}

.service-group {
    margin-top: 50px;
    display: flex;
    justify-content: space-between;

    @include mobile {
        display: block;
    }
}

.service-button-group {
    display: inline-block;

    &.forward {
        display: flex;

        @include mobile {
            display: block;
        }
    }

}

.service-summary-delivery {
    .item-address {
        position: relative;

        input {
            width: 100%;
            padding: 0 5px;

            &[type="radio"] {
                width: 15px;
                position:absolute;
                top: 25px;
                left: 0;
            }
        }
    }
}
.aimeos {
    &.account-profile {
        .item-address {
            height: 100%;
            border: 1px solid $gray-border;

            .header {
                height: 100%;

                h3 {
                    font-weight: $font-regular;
                }
                .modify {
                    background-color: $primary-color;
                    color: $white;
                    border-radius: 15px;
                    padding: 3px 20px;
                }
            }

            .customer-info-item {
                font-size: 17px;
            }

            .header,
            .content {
                background: transparent;
                padding: 20px 60px;
                margin: 0;
            }
        }
    }
}
