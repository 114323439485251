.main-navigation {
    .catalog-filter {
        .catalog-filter-tree {
            & > ul {
                text-align: center;
                @include mobile {
                    margin-left: 0;
                }

                & > li {
                    & > a {
                        display: inline-block;
                    }

                    & > ul {
                        padding: 10px 0;
                        box-shadow: none;
                        border: 0;
                        background: $primary-color;

                        & > li {
                            padding: 3px 15px;
                            margin-bottom: 5px;

                            a {
                                padding: 0;
                                color: $white;
                                text-transform: uppercase;
                            }

                            &:last-child {
                                margin-bottom: 0;
                            }
                            &.active,
                            &:hover {
                                background: $white;

                                a {
                                    color: $primary-color;
                                }
                            }
                        }
                    }

                    svg.arrow-down {
                        display: inline-block;
                        width: 22px;
                        height: 22px;
                        margin-left: -15px;
                        margin-top: 3px;
                        fill: $white;
                        padding-right: 5px;
                        cursor: pointer;

                        @include mobile {
                            display: none;
                        }
                    }

                    &:last-child {
                        margin-right: 0;
                    }

                    &.active,
                    &:hover {
                        background: $white;

                        & > a {
                            color: $black;
                        }

                        svg.arrow-down {
                            fill: $primary-color;
                        }
                    }
                }
            }
        }
    }
}

.meta-navigaton--basket {
    line-height: 12px;

    span {
        font-family: "Neuzeit Bold", sans-serif;
    }
}

.first-level {
    justify-content: center;
    padding: 0;
    text-align: center;
    flex-wrap: nowrap;
    align-items: center;
    line-height: 1.8;
    margin-bottom: 0;
    margin-block-end: 0;

    .nav-item {
        margin: 0 1rem;
        display: inline-block;

        @include mobile {
            width: 100%;
        }

        &.active {
            a {
                color: #000;
                background-color: #fff;
            }
        }

        a {
            display: inline-block;
            height: 45px;
            text-transform: uppercase;
            padding: 10px 14px;
            font-size: 16px;
            color: #fff;

            &:hover {
                color: #000;
                background-color: #fff;
            }
        }
    }
}

.shop-navigation {
    background-color: #fff;
    display: none;
    min-height: 44px;
    z-index: 10;
    position: relative;

    &.show {
        display: block;
    }

    .catalog-filter-tree {
        text-align: center;
        margin: 0;

        > ul {
            margin: 0;
            padding: 0;
            display: flex;
            justify-content: center;
            text-align: center;
            flex-direction: row;
            flex-wrap: nowrap;
            align-items: center;

            > li {
                display: inline-block;
                font-size: 16px;
                text-transform: uppercase;
                font-weight: 200;
                display: block;
                margin-left: 0;
                margin-right: 0;
                padding: 0;
                position: relative;

                a {
                    display: inline-block;
                    text-transform: uppercase;
                    padding: 10px 14px;
                    font-size: 16px;
                    font-weight: normal;
                    color: #000;
                }

                > ul {
                    display: none;
                }

                &.active {
                    a {
                        background-color: #ececec;
                        color: #000;
                        font-weight: bold;
                    }
                }

                &:hover {
                    a {
                        background-color: #ececec;
                        color: #000;
                    }

                    > ul {
                        position: absolute;
                        display: block;
                        left: 0;
                        transition: all 0.2s ease;
                        background: #ececec;
                        margin: 0;
                        width: 100%;
                        padding: 0;

                        > li {
                            a {
                                color: #000;
                                text-transform: none;
                                font-weight: normal;
                            }

                            &.active,
                            &:hover {
                                a {
                                    color: #000;
                                    font-weight: bold;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
