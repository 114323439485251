.info {
    position: relative;
    display: inline-block;
    margin-left: 5px;

    .info-text {
        display: none;
        position: absolute;
        width: 265px;
        background: $white;
        padding: 20px;
        height: auto;
        font-size: 12px;
        font-weight: $font-light;
        text-align: left;
        white-space: normal;
        z-index: 9999;
        box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);

        ul,
        ol {
            margin: 0;
            padding-left: 20px;

            li {
                padding: 7px 0;
            }
        }

        &.right {
            right: -275px;
            left: auto;

            @include mobile {
                right: 0;
                left: 50%;
                transform: translateX(-50%);
            }
        }

        &.left {
            left: -275px;

            @include mobile {
                right: 0;
                left: 50%;
                transform: translateX(-50%);
            }
        }

        &.bottom {
            left: -155px;
            @include mobile {
                right: 0;
                left: 50%;
                transform: translateX(-50%);
            }
        }

        @include mobile {
            top: 50vh;
        }
    }

    .icon:hover {
        & ~ .info-text {
            display: block;

            @include mobile {
                display: none;
            }
        }
    }

    @include mobile {
        position: unset !important;
    }

}

.all-around-protection-hint {
    margin: 10px 0;
    border: 1px solid $success-green;
    border-radius: 12px;
    padding: 10px 15px;

    .protection-hint-text {
        color: $success-green;
        display: block;
        line-height: 20px;
        font-size: 15px;
    }
}
