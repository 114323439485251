.header-height{
    height: 179px;
}

.header-right{
    height:150px;
}

header.header {

    & > .container {
        padding-bottom: 15px;
        padding-top: 15px;
    }

    &.plain {
        @include mobile {
            .header--logo a {
                text-align: center;
            }
        }
    }

    .plain-welcome {
        align-self: center;

        h1 {
            margin-bottom: 0;
        }

        @include mobile {
            margin: 25px 0 5px;
            text-align: center;
        }
    }

    .login {
        .header--logo {
            @include mobile {
                padding-bottom: 15px;
            }
        }
    }

    .header-left {
        .header--menu-button {
            position: relative;
            width: 100%;
        }
    }

    .header--logo {
        @include mobile {
            padding-right: 7px;
        }

        a {
            display: block;
            width: 100%;
            text-align: right;

            @include tablet {
                text-align: left;
            }
        }

        img {
            max-width: 200px;
            position: relative;
            max-height: 125px;
            @include desktop {
                max-width: 100%;
            }
        }
    }

    img {
        max-width: 100%;
    }

    .catalog-filter-search {

        .value,
        .reset {
            border-color: darken($primary-color, 10%);
        }

        .btn-primary {
            padding: 0;
            text-align: center;

            .search {
                width: 30px;
                height: 30px;
                display: block;
                margin: 0 auto;
            }
        }
    }

    .brand--logo {
        text-align: right;
        padding: 10px 0;
        margin-top: 15px;

        img {
            max-width: 175px;

            @media (min-width: 768px) and (max-width: 991px) {
                max-width: 100px;
            }
        }
    }

    @include mobile {
        background-color: $white;
    }

    .calculate_leasing_box {
        padding-left: 20px;

        .calculate_leasing {
            &:hover {
                .calculator-bubble {
                    transform: scale(1.2);
                }
            }

            .calculate_leasing_text {
                background-color: #ECECEC;
                font-size: 13px;
                padding: 4px 4px 4px 25px;

                @include desktop {
                    padding: 4px 4px 4px 38px;
                }
                @include mobile {
                    padding: 5px 10px 5px 35px;
                    font-size: 12px;
                }

                span {
                    color: $primary-color;
                    font-weight: $font-bold;
                }

                .calculator-bubble {
                    position: absolute;
                    width: 48px;
                    height: 48px;
                    left: 0;
                    top: -9px;
                    transition: .3s transform ease;

                    @include mobile {
                        left: 10px;
                    }
                }
            }

            @include mobile {
                margin-bottom: 20px;
            }
        }
    }
}
