.catalog-list {

    .list-search {
        margin: 50px 0;
        text-align: center;
    }

    .product {
        a {
            max-width: 100%;

            .media-list {
                height: 240px;
                position: relative;
                background: $white;

                .bu_media-item {
                    position: absolute;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    top: 0;
                    opacity: 0;
                    transition: opacity 0.3s ease-in-out;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    overflow: hidden;

                    &:nth-child(1) {
                        opacity: 1;
                    }

                    img {
                        width: auto;
                        height: auto;
                        max-width: 100%;
                        max-height: 100%;
                        flex: 0 0 auto;
                    }
                }

                &:hover {
                    .bu_media-item:nth-child(1) {
                        opacity: 0;
                    }
                    .bu_media-item:nth-child(2) {
                        opacity: 1;
                        z-index: 999;
                    }
                }
            }

            .offer {
                max-width: 100%;
                margin-top: 30px;
            }
        }

        @include mobile {
            margin-bottom: 55px;
        }
    }
}

.media-list {
    height: 240px;
    position: relative;
    background: $white;
    margin: 0 auto;
    .bu_media-item {
        position: absolute;
        left: 50%;
        right: 0;
        bottom: 0;
        top: 0;
        opacity: 0;
        transition: opacity .3s ease-in-out;

        &:nth-child(1) {
            opacity: 1;
        }

        img {
            max-height: 100%;
            top: 0;
            left: 0;
            transform: initial;
        }
    }

    &:hover {
        .bu_media-item:nth-child(2) {
            opacity: 1;
            z-index: 999;
        }
    }
}
