.c-primary {
    color: $primary-color !important;
}

.c-white {
    color: $white !important;
}

.c-black {
    color: $black !important;
}

.c-dark-gray {
    color: $dark-gray-font !important;
}

.no-p {
    padding: 0 !important;
}

.no-m {
    margin: 0 !important;
}

.font-bold {
    font-weight: $font-bold !important;
}

.font-regular {
    font-weight: $font-regular !important;
}

.bc-p {
    border-color: $primary-color;
}

.b-transparent {
    background-color: transparent !important;
}

.no-border {
    border: 0 none !important;
}

.b-primary {
    background-color: $primary-color;
}

.b-white {
    background-color: $white !important;
}

.border-radius-15 {
    border-radius: 15px;
}

.t-no-wrap {
    white-space: nowrap;
}

.t-capitalized {
    text-transform: capitalize !important;
}

.t-uppercase {
    text-transform: uppercase !important;
}

.border-gray {
    border-color: $gray-border !important;
}

.o-hidden {
    overflow: hidden !important;
}

.o-auto {
    overflow-y: auto;
}

.mr-30 {
    margin-right: 30px !important;
}


