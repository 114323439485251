.aimeos.catalog-list {
    .product {
        border-bottom: 0;

        .price-list {
            padding: 15px 0;
            background-color: $primary-color;
            width: 100%;
            color: $white;
            font-size: 18px;
            font-weight: $font-light;
            height: 33px;

            &:hover {
                .price-text {
                    margin-top: -26px;
                }
            }

            .price-actual {
                height: 26px;
                overflow: hidden;

                .price-text {
                    transition: all .3s ease;
                    padding-top: 2px;

                    &.isloading {
                        display: none;
                    }
                }

                .calc-icon {
                    display: none;
                }

                .ordernow__button-text.isloading,
                .cost.isloading {
                    display: none;
                }
            }
        }

        .text-list {
            margin: 2em auto 1em auto;
            height: 90px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-top: 1px dashed $light-gray;
            padding-top: 10px;

            h2 {
                font-size: 18px;
                text-transform: inherit;
                font-weight: $font-bold;
                height: auto;
                color: $dark-gray;
                line-height: 20px;
                margin-bottom: 0;
            }

            .text-item {
                font-size: 18px;
                height: auto;
            }
        }

        .offer {
            margin-top: 15px;
        }
    }

    .catalog-detail {
        .ordernow__button-text {
            display: none;
        }
    }
}

@keyframes opacitychange {
    0%, 100% {
        opacity: 0;
    }

    60% {
        opacity: 1;
    }
}


.last-seen.catalog-list .media-item {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

// Overwrite placeholder
.catalog-list a .media-list,
.aimeos .suggest-item .item-image,
.basket-related-bought .media-list,
.catalog-detail-bought .media-list,
.catalog-detail-bundle .media-list,
.catalog-detail-suggest .media-list,
.catalog-session-seen .media-list,
.account-watch .media-item,
.account-favorite .media-item,
.basket-related-bought .media-item,
.catalog-detail-bought .media-item,
.catalog-detail-bundle .media-item,
.catalog-detail-suggest .media-item,
.catalog-session-seen .media-item,
.catalog-session-pinned .media-item,
.catalog-detail-image .image-single,
.catalog-detail-image .bu-image-single{
    background-image: url("../images/placeholder.png");
}

#loader-dots.isloading {
    display: inline-block;

    span {
        display: inline-block;
        width: 8px;
        height: 8px;
        border-radius: 100%;
        background-color: #fff;
        margin: 12px 5px;
        opacity: 0;

        &:nth-child(1) {
            animation: opacitychange 1s ease-in-out infinite;
        }

        &:nth-child(2) {
            animation: opacitychange 1s ease-in-out 0.33s infinite;
        }

        &:nth-child(3) {
            animation: opacitychange 1s ease-in-out 0.66s infinite;
        }
    }
}


.show-details.isloading {
    display: none !important;
}

