.all-around-protection {
    .protection-head {
        padding: 45px 0;
        font-size: 17px;

        .headline-text {
            margin-bottom: 6px;
            display: inline-block;
        }

        .protection-headlines {
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;

            .protection-text {
                font-size: 21px;
                align-self: self-end;
            }
        }

        .icon {
            width: 160px;
            height: 160px;

            &.download {
                width: 30px;
                height: 30px;
                margin-right: 15px;
            }
        }

        .download-link {
            font-size: 17px;
            margin-top:25px;
        }

        .headlines {
            padding-top: 15px;

            h1 {
                margin-bottom: 20px;
                font-weight: $font-regular;
            }
        }
    }

    .service-portal {
        padding: 15px;
        font-size: 24px;
        background-color: $primary-color;
        font-family: "Bradford Medium", sans-serif;
        color: $white;
        min-height: 100px;
        .img-holder {
            display: flex;
            align-items: center;
            svg {
                stroke: $white;
                fill: none;
                max-height: 70px;
            }
        }

        .content {
            display: flex;
            flex-direction: row;
            align-items: center;

            @media (min-width: 0px) and (max-width: 767px) {
                flex-direction: column;
                align-items: flex-start;
                margin-top: 5px;
            }
            justify-content: space-between;

            p {
                margin: 0;
            }
        }

        .portal-button {
            display: inline-block;
            cursor: pointer;
            background-color: $white;
            border-radius: 50px;
            color: $primary-color;
            font-size: 21px;
            padding: 0.1rem 1.2rem;
            margin-right: 2rem;
            text-align: center;

            @include mobile {
                padding: 0.1rem 0.6rem;
            }
        }
    }

    .card {
        border: 1px solid $success-color;
        width: 100%;

        .card-header {
            background-color: $success-color;

            h4 {
                color: $white;
                text-transform: uppercase;
                font-weight: $font-light;
                margin-bottom: 0;
            }
        }
    }

    .benefit {
        font-weight: $font-bold;
        font-size: 15px;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        &:before {
            content: "";
            @extend .haken--icon;
            width: 40px;
            height: 40px;
            display: inline-block;
        }
    }
}
