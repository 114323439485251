.delivery-status {
    width: 15px;
    height: 15px;
    display: inline-block;
    background-color: #000;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    margin-right: 15px;

    &.status-unfinished {
        background-color: #6DA0BE;
    }

    &.status-deleted {
        background-color: #A10000;
    }

    &.status-pending {
        background-color: #6DA0BE;
    }

    &.status-progress {
        background-color: #6DA0BE;
    }

    &.status-dispatched {
        background-color: #6DA0BE;
    }

    &.status-delivered {
        background-color: #89C882;
    }

    &.status-lost {
        background-color: #A10000;
    }

    &.status-refused {
        background-color: #A10000;
    }

    &.status-returned {
        background-color: #A10000;
    }

    &.status-active {
        background-color: #89C882;
    }

    &.status-end {
        background-color: #ABABAB;
    }

    &.status-open {
        background-color: #E8A541;
    }

}

.delivery-status-name {
    margin-left: 20px;
}

