.stroke-default {
    stroke: #FFFFFF !important;
}

.bicicli-iframe {
    width: 100%;
    max-width: 1450px;
    padding-left: 33px;
    height: 880px;
    border: #FFFFFF;
}

.bicicli-iframe-attributes {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 80px;
    border: white;
    background: #FFFFFF;
}

@media (max-width: 1300px) {
    .bicicli-iframe {
        padding: 0 20px 0 20px;
    }
}

@media (max-width: 1032px) {
    .bicicli-iframe {
        height: 1360px;
    }
}

@media (max-width: 679px) {
    .bicicli-iframe {
        height: 1280px;
    }
}

@media (max-width: 300px) {
    .bicicli-iframe {
        height: 1350px;
    }
}

@if variable-exists(alternate-1) {
    .alternate-color-1 {
        color: $alternate-1 !important;
    }

    .alternate-bg-1 {
        background-color: $alternate-1 !important;
    }

    .alternate-border-1 {
        border-color: $alternate-1 !important;
    }

    .alternate-fill-1 {
        fill: $alternate-1 !important;
    }

    .alternate-border-box-1 {
        border: 1px solid $alternate-1 !important;
    }

    .alternate-border-bottom-1 {
        border-bottom: 1px solid $alternate-1 !important;
    }

    @if variable-exists(alternate-hover-1) {

        .alternate-color-hover-1 {
            color: $alternate-1 !important;
        }

        .alternate-stroke-hover-1 {
            stroke: $alternate-1 !important;
        }

        .alternate-bg-hover-1 {
            background-color: $alternate-1 !important;
        }

        .alternate-border-hover-1 {
            border-color: $alternate-1 !important;
        }

        .alternate-color-hover-1:focus,
        .alternate-color-hover-1:hover {
            color: $alternate-hover-1 !important;
        }

        .alternate-bg-hover-1:focus,
        .alternate-bg-hover-1:hover {
            background-color: $alternate-hover-1 !important;
        }

        .alternate-border-hover-1:focus,
        .alternate-border-hover-1:hover {
            border-color: $alternate-hover-1 !important;
        }

        .alternate-stroke-hover-1:focus,
        .alternate-stroke-hover-1:hover {
            stroke: $alternate-hover-1 !important;
        }
    }
}


@if variable-exists(alternate-2) {
    .alternate-color-2 {
        color: $alternate-2 !important;
    }

    .alternate-bg-2 {
        background-color: $alternate-2 !important;
    }

    .alternate-border-2 {
        border-color: $alternate-2 !important;
    }

    .alternate-fill-2 {
        fill: $alternate-2 !important;
    }
    .alternate-stroke-2 {
        stroke: $alternate-2 !important;
    }

    .alternate-border-box-2 {
        border: 1px solid $alternate-2 !important;
    }

    .alternate-border-bottom-2 {
        border-bottom: 1px solid $alternate-2 !important;
    }

    .price-list {
        color: $alternate-2 !important;
    }

    @if variable-exists(alternate-hover-2) {

        .alternate-color-hover-2 {
            color: $alternate-2 !important;
        }

        .alternate-stroke-hover-2 {
            stroke: $alternate-2 !important;
        }

        .alternate-bg-hover-2 {
            background-color: $alternate-2 !important;
        }

        .alternate-border-hover-2 {
            border-color: $alternate-2 !important;
        }

        .alternate-color-hover-2:focus,
        .alternate-color-hover-2:hover {
            color: $alternate-hover-2 !important;
        }

        .alternate-bg-hover-2:focus,
        .alternate-bg-hover-2:hover {
            background-color: $alternate-hover-2 !important;
        }

        .alternate-border-hover-2:focus,
        .alternate-border-hover-2:hover {
            border-color: $alternate-hover-2 !important;
        }

        .alternate-stroke-hover-2:focus,
        .alternate-stroke-hover-2:hover {
            stroke: $alternate-hover-2 !important;
        }
    }


}


@if variable-exists(alternate-3) {
    .alternate-color-3 {
        color: $alternate-3 !important;
    }
    .alternate-stroke-3 {
        stroke: $alternate-3 !important;
    }

    .alternate-bg-3 {
        background-color: $alternate-3 !important;
    }


    .alternate-border-3 {
        border-color: $alternate-3 !important;
    }

    .alternate-fill-3 {
        fill: $alternate-3 !important;
    }
    .alternate-border-box-3 {
        border: 1px solid $alternate-3 !important;
    }
    .alternate-border-bottom-3 {
        border-bottom: 1px solid $alternate-3 !important;
    }

    @if variable-exists(alternate-hover-3) {

        .alternate-color-hover-3 {
            color: $alternate-3 !important;
        }

        .alternate-stroke-hover-3 {
            stroke: $alternate-3 !important;
        }

        .alternate-bg-hover-3 {
            background-color: $alternate-3 !important;
        }

        .alternate-border-hover-3 {
            border-color: $alternate-3 !important;
        }

        .alternate-color-hover-3:focus,
        .alternate-color-hover-3:hover {
            color: $alternate-hover-3 !important;
        }

        .alternate-bg-hover-3:focus,
        .alternate-bg-hover-3:hover {
            background-color: $alternate-hover-3 !important;
        }

        .alternate-border-hover-3:focus,
        .alternate-border-hover-3:hover {
            border-color: $alternate-hover-3 !important;
        }

        .alternate-stroke-hover-3:focus,
        .alternate-stroke-hover-3:hover {
            stroke: $alternate-hover-3 !important;
        }
    }


}

@if variable-exists(alternate-4) {
    .alternate-color-4 {
        color: $alternate-4 !important;
    }
    .alternate-stroke-4 {
        stroke: $alternate-4 !important;
    }

    .alternate-bg-4 {
        background-color: $alternate-4 !important;
    }

    .alternate-border-4 {
        border-color: $alternate-4 !important;
    }

    .alternate-fill-4 {
        fill: $alternate-4 !important;
    }

    .alternate-border-box-4 {
        border: 1px solid $alternate-4 !important;
    }

    .alternate-border-bottom-4 {
        border-bottom: 1px solid $alternate-4 !important;
    }

    @if variable-exists(alternate-hover-4) {

        .alternate-color-hover-4 {
            color: $alternate-4 !important;
        }

        .alternate-stroke-hover-4 {
            stroke: $alternate-4 !important;
        }

        .alternate-bg-hover-4 {
            background-color: $alternate-4 !important;
        }

        .alternate-border-hover-4 {
            border-color: $alternate-4 !important;
        }

        .alternate-color-hover-4:focus,
        .alternate-color-hover-4:hover {
            color: $alternate-hover-4 !important;
        }

        .alternate-bg-hover-4:focus,
        .alternate-bg-hover-4:hover {
            background-color: $alternate-hover-4 !important;
        }

        .alternate-border-hover-4:focus,
        .alternate-border-hover-4:hover {
            border-color: $alternate-hover-4 !important;
        }

        .alternate-stroke-hover-4:focus,
        .alternate-stroke-hover-4:hover {
            stroke: $alternate-hover-4 !important;
        }
    }

}

@if variable-exists(alternate-boxes) {
    input[type=radio]:checked {
        background: $alternate-boxes !important;
        border: 1px solid $alternate-boxes !important;
    }

    input[type=checkbox]:checked ~ span.checkbox {
        background: $alternate-boxes !important;
        border: 1px solid $alternate-boxes !important;
    }

    .alternative-flex {
        display: flex;
        justify-content: center !important;
    }

}

.linktext {
    font-family: "Bradford Medium", sans-serif !important;
    color: #3b3b3b !important;
    a:link, a:visited, a:hover, a:active {
        color: #3b3b3b !important;
        text-decoration: underline !important;
    }
}




