.catalog-filter-attribute {
    margin: 2em 0;

    .attribute-selected {
        .selected-all,
        .attr-name {
            border-radius: 4px;
            padding: 2px 8px;
            transition: ease all .3s;

            &:hover {
                border-color: $primary-color;
            }
        }
    }

    .attribute-lists {
        display: block;

        .attr-list {
            height: auto;
            display: none;
        }
        .attr-item{
            &.disabled{
                display: none;
            }
            .attr-count{
                display: none;
            }
        }
    }

    label {
        &:before {
            width: 17px;
            height: 17px;
            line-height: 15px;
            font-size: 14px;
            text-align: center
        }
    }

    fieldset {
        border: none;
        padding: 0 0.5em;
        width: 25%;
        margin: 0.5em 0;

        legend {
            text-align: center;
        }

        @include mobile {
            width: 100%;
        }
    }



    legend {
        width: 100%;
        background-color: $primary-color;
        padding: 10px 0;
        color: $white;
        border: none;
        font-weight: $font-light;
        transition: ease all .3s;

        &:hover {
            background-color: darken($primary-color, 10%);
        }

        &:before {
            content: none;
        }

        &:after {
            content: " \25BC";
            font-size: 8px;
            position: absolute;
            top: 50%;
            margin-top: -4px;
            margin-left: 5px;
        }
    }

    &.cat_10{
        .attr-1036619{display: none !important;}
        .attr-449474{display: none !important;}
    }
    &.cat_12{
        .attr-1036619{display: none !important;}
        .attr-449474{display: none !important;}
    }
    &.cat_14{
        .attr-1028791{display: none !important;}
        .attr-1029156{display: none !important;}
    }
    &.cat_16{
        .attr-1029156{display: none !important;}
        .attr-1029156{display: none !important;}
    }
}
