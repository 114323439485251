.pop-up-layer {
    background-color: $black;
    opacity: 0;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: -1;
    transition: opacity .6s ease-in-out;

    &.open {
        opacity: .7;
        z-index: 10;
    }
}

.pop-up-form {
    position: fixed;
    top: 25px;
    z-index: -1;
    left: 50%;
    width: 0;
    opacity: 0;
    transform: translate(-50%, 100%);
    transition: all .3s ease-in-out;
    bottom: 25px;

    label {
        font-weight: $font-bold;
        margin: 0;
    }

    &.open {
        transform: translate(-50%);
        opacity: 1;
        z-index: 999;
        width: 100%;
    }

    .container {
        padding-top: 80px;

        @include mobile {
            padding-top: 110px;
        }
    }

    .popup-header {
        display:flex;
        justify-content: space-between;
        align-items: center;
        font-size: 23px;
        color: $white;
        padding: 15px;
        position: fixed;
        left: 50%;
        transform: translateX(-50%);
        z-index: 10000;
        margin-left: -1px;
    }

    .popup-body {
        overflow-y: scroll;
        max-height: 87vh;
        overflow-x: hidden;
    }

    .pop-up-container{
        padding: 30px 15px;
        background-color: $white;
        width: 100%;

        .pop-up-btn-group {
            display: flex;
            justify-content: space-between;
            align-items: center;

            span {
                font-size: 17px;
            }

            @include mobile {
                display: block;
            }
        }
    }

    .order-tracking {
        &.col-sm-6 {
            padding: 0;
        }
    }

    .pop-up-headline {
        display: flex;
        justify-content: space-between;

        span {
            &.popup-back {
                font-size: 25px;
                font-weight: $font-regular;
                margin-right: 15px;
                color: $primary-color;

                &:hover {
                    cursor: pointer;
                }
            }
        }
    }
}

.history-list order-history > .closebtn {
    display: none;
}

.scrollable {
    max-height: 100%;
    overflow-y: scroll;
    background: #fff;
}

.icon {
    &.delete {
        width: 20px;
        height: 20px;
        margin-right: 10px;
    }

    &.closebtn {
        width: 25px;
        height: 25px;
    }
}
