.order-announcement {
    background-color: $primary-color;

    .mpp-text {
        color: $white;
    }
}

.slider {
    #homepage-slider {

        @include tablet {
            margin-top: 0;
        }
    }
}

.home-img {
    margin: 0;
    max-width: 100%;
    padding: 0;
    overflow: hidden;
    border-radius: 20px;

    img {
        max-width: 100%;
    }
}
