.catalog-detail {

    .catalog-detail--stage {

        .catalog-stage-navigator {
            float: none;
            width: 100%;

            nav {
                justify-content: flex-end;
            }
        }
    }

    .catalog-detail-calculate-saving {
        margin-top: 25px;

        a {
            display: block;
            padding: 25px 25px 22px 25px;
            background: $primary-color;
            color: $white !important;
            text-align: center;
            line-height: normal;
            font-size: 18px;
            border-radius: 10px;

            @include mobile {
                font-size: 16px;
                padding: 20px 25px 18px 25px;
            }
        }
    }

    .catalog-detail-additional {
        padding: 25px;
        background: $white;
        margin-top: 25px;

        .header {
            border-radius: 10px;
            border: 1px solid $primary-color;
            display: inline-block;
            font-family: "Neuzeit Regular", sans-serif;
            font-size: 14px;
            color: $primary-color;
            line-height: 14px;
            padding: 10px 35px 7px 35px;
            margin: 0;
            text-transform: inherit;
            text-align: center;
            width: 100%;
            margin-bottom: 5px;

            &.opened,
            &:hover {
                background: $primary-color;
                color: $white;
            }

            @include desktop {
                margin-bottom: 0;
                width: auto;
            }
        }

        .additional-box {

            .content {
                margin: 50px 0 25px;
                padding: 0;
            }

            @include desktop {
                &.description {
                    .item {
                        padding: 0;
                        -webkit-column-count: 2;
                        -moz-column-count: 2;
                        column-count: 2;
                        column-gap: 80px;
                        -webkit-column-gap: 80px;
                        -moz-column-gap: 80px;
                    }
                }
            }
        }
    }

    .catalog-detail-basket {

        .stock-list {
            width: unset;
            padding: 5px 0;

            .stockitem {
                .stocktext {
                    color: $dark-gray !important;
                    font-family: "Neuzeit Bold", sans-serif;
                    margin: 0;
                }
            }
        }

        .main-product {
            padding-right: 50px;
            display: inline-block;

            .info {
                margin-left: 0;

                .info-small {
                    display: flex;
                    align-items: center;
                }
            }

            @include mobile {
                margin-bottom: 10px;
            }
        }

        .addbasket {
            margin: 0;

            .input-group {
                display: flex;
                justify-content: flex-end;

                button {
                    width: 100%;
                    border-radius: 15px;
                    font-family: "Neuzeit Regular", sans-serif;
                    font-size: 16px;
                    line-height: normal;
                    padding: 15px;
                }
            }
        }

        @include tablet {
            .first {
                display: flex;
            }
        }
    }

    .catalog-detail-basic {
        background: $white;
        padding: 25px;
        margin-bottom: 35px;

        & + .catalog-detail-basic {
            margin-bottom: 25px;
        }

        .short {
            margin-top: 15px;
        }

        .product-description {
            margin-bottom: 25px;
        }

        .product-button {
            @include mobile {
                margin: 10px 0;
            }
        }

        h1.name {
            padding: 0;
            margin: 0;
            font-weight: normal;
            font-size: 25px;
            line-height: normal;

            @include mobile {
                font-size: 20px;
            }
        }

        .all-round-protection {
            margin-right: 30px;
            display: block;
            margin-bottom: 20px;
            margin-top: 10px;

            a {
                text-decoration: underline;

                @include mobile {
                    margin: 0;
                }
            }

            @include mobile {
                margin-bottom: 25px;
            }
        }
    }

    .product {

        .info-small {
            width: 100%;
            height: auto;
            cursor: pointer;

            .label {
                border-bottom: 1px solid;
                margin-left: 5px;
                line-height: normal;
            }
        }

        .price-list {
            margin-top: 25px;
            display: block;
            text-align: left;

            & > .row {
                align-items: center;
            }

            .catalog-detail-basket-attribute {
                display: none;
            }

            .price-actual {
                .price-text {
                    line-height: normal;

                    .value {
                        font-weight: normal;
                        vertical-align: inherit;
                        font-family: "Neuzeit Bold", sans-serif;
                    }

                    @include mobile {
                        text-align: left;
                    }
                }

                .currency_star {
                    font-size: 46px;
                    line-height: normal;
                    font-family: "Neuzeit Bold", sans-serif;
                }
            }

            @include desktop{
                margin-top: 0;
            }
        }
    }

    @include mobile {
        margin: 0;
    }

    .catalog-detail-image {
        @include mobile {
            padding: 25px;

            .image-single {
                height: 200px;

                .item {
                    height: 200px;
                }
            }
        }

        margin-top: 0;
        padding: 25px 25px 0 25px;
        margin-bottom: 0;
        max-width: 100%;
        width: 100%;
    }

    .product-specs {
        width: 100%;
    }
}


.catalog-session {
    padding: 0;
    margin-top: 35px;
}

.catalog-detail-basket {
    margin: 0;
}

.catalog-detail-similar{
    margin-top: 35px;
    .header{
        padding: 10px 1em;
    }
}

.catalog-detail--stage {
    .catalog-stage-navigator {
        display: none;
    }
}

.detail-info-hint-box {
    @include mobile {
        margin-bottom: 50px;
    }

    .product-detail-info-text {
        font-size: 14px;
        font-family: "Neuzeit Regular", sans-serif;
        color: $el-black-font;
    }

    .info {
        min-width: 30px;
        max-height: 30px;
        margin-right: 15px;
        margin-left: 0;
    }
}

.new-badge-detail {
    margin: 5px 20px;
    height: 25px;
    z-index: 1;
    float:right;
}

.preorder-box {
  display: flex;
  margin-top:30px
}

.preorder-info {
    width: 52px !important;
    margin: 15px 5px 15px;
}

.preorder-text {
    padding: 8px 3px 4px;
}
