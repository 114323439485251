
.calc-icon {
    width: 30px;
    height: 30px;
    display: inline-block;
    vertical-align: middle;
    position: relative;

    .arrow-down,
    .arrow-up {
        display: none;
        width: 5px;
        height: 5px;
        position: absolute;
        left: 50%;
        bottom: -20px;
        transform: translateX(-50%);
    }

    &:hover {
        cursor: pointer;

        .arrow-down {
            display: inline-block;
        }
    }

    .calculator {
        &.opened {
            &:hover {
                & + .arrow-up + .arrow-down {
                    display: none;
                }
            }
            & + .arrow-up {
                display: inline-block;
            }
        }
    }
}

.calculator-saving-layer {
    display: none;
    background-color: $light-gray;
    padding: 8px;

    &.opened {
        display: block;
    }

    .saving-box {
        background-color: $dark-gray;
        color: $white;
    }

    .button-layer {
        padding: 15px 0;
        @include mobile {
            a[href='#calculator_details'] {
                div {
                    margin-bottom: 7px;
                }
            }
            .back {
                bottom: -17px;
                right: -3px;
            }
        }

        .back {
            font-size: 25px;

            &:hover {
                cursor: pointer;
            }
        }

        a:first-child {
            div {
                background-color: $white;
                border-radius: 20px;
                text-align: center;
                padding: 2px 0;
                margin-bottom: 5px;

                span {
                    color: $primary-color;
                }
            }
        }

        a:nth-child(2) {
            div {
                background-color: $white;
                border-radius: 20px;
                text-align: center;
                padding: 2px 0;

                span {
                    color: $primary-color;
                }
            }
        }
    }

    .saving-box, .costs-box, .uvp-box, .button-layer {
        margin: 0 0 10px 0;
        @include mobile {
            padding-bottom: 5px;
        }
    }

    .text-left {
        text-align: left;
    }

    .text-right {
        text-align: right;
    }
}
