.aimeos{
  .catalog-detail-similar{
    .product {
      border-bottom: 0 none;
      .price-list {
        padding: 13px 0 15px 0;
        background-color: $primary-color;
        width: 100%;
        color: $white;
        font-size: 18px;
        font-weight: $font-light;

        &:hover {
          .price-text {
            margin-top: -28px;
          }
        }

        .price-actual{
          .price-text {
            transition: all .3s ease;
            .value{
              font-size: 18px;
              font-weight: $font-light;
            }
            .currency{
              font-size: 18px;
            }
          }
          .ordernow__button-text{
            display: block;
          }
        }
      }
      .mpp-price-item{
        height: 26px;
        overflow: hidden;
      }

      h3.name{
        font-size: 19px;
        text-transform: inherit;
        font-weight: 700;
        color: #3b3b3b;
        line-height: 23px;
        margin-bottom: 0;
        height: 80px;
      }
    }
  }

}
