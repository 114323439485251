.faq-content {
    .eleasageht {
        max-width: 50%;
    }
}

.faq {
    .faq-header {
        .topic {
            display: inline-block;
            margin-right: 30px;
            @include noselect;

            &:last-child {
                margin-right: 0;
            }
        }
    }

    .faq-body {
        margin-top: 15px;

        .faqs {
            display: none;

            &.active {
                display: block;
            }

            .faq-item {
                margin-bottom: 15px;

                &.active {
                    .question {
                        .arrow-down {
                            display: none;
                        }

                        .arrow-up {
                            display: block;
                        }
                    }

                    .answer {
                        display: block;
                    }
                }

                .question {
                    cursor: pointer;
                    position: relative;
                    margin-right: 15px;
                    @include noselect;

                    .arrow-down,
                    .arrow-up {
                        width: 20px;
                        height: 20px;
                        position: absolute;
                        right: -20px;
                        top: 0;
                    }

                    .arrow-up {
                        display: none;
                    }
                }

                .answer {
                    margin-top: 5px;
                    display: none;
                    @include noselect;

                    a {
                        color: $primary-color;
                    }
                }
            }
        }
    }
}
