button,
.btn,
.btn-primary,
.btn-secondary,
.btn-primary--light,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
    outline: 0 none !important;
    box-shadow: none;
    cursor: pointer;

    @include pseudos {
        outline: 0 none !important;
        box-shadow: none;
    }
}

.aimeos {
    .btn-primary {
        background-color: $primary-color;
        border: 1px solid $primary-color;

        @include pseudos {
            background-color: darken($primary-color, 10%);
            border: 1px solid darken($primary-color, 10%);
        }
    }
}

.btn {
    background: $light-gray;
    border: 1px solid $light-gray;
    border-radius: 0;
    padding: 5px 25px;
}

.btn-primary,
.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
    background-color: $primary-color;
    border: 1px solid $primary-color;

    @include pseudos {
        background-color: darken($primary-color, 10%);
        border: 1px solid darken($primary-color, 10%);
    }
}


.btn-primary--light {
    background-color: lighten($primary-color, 10%);
    border: 1px solid lighten($primary-color, 10%);

    @include pseudos {
        background-color: lighten($primary-color, 5%);
        border: 1px solid lighten($primary-color, 5%);
    }
}


.btn-secondary {
    background-color: $secondary-color;
    border: 1px solid $secondary-color;

    @include pseudos {
        background-color: darken($secondary-color, 10%);
        border: 1px solid darken($secondary-color, 10%);
    }
}

.btn-primary--rounded {
    border-radius: 25px;
    padding: 5px 25px;
    width: auto;
    background: $primary-color;
    border: 1px solid $primary-color;
    color: $white;
    font-weight: $font-light;

    @include pseudos {
        color: $white;
        background-color: darken($primary-color, 10%);
        border: 1px solid darken($primary-color, 10%);
    }
}

.btn-gray {
    background-color: #bababa;
    border-color: #bababa;
    color: $white;
    border: 0 none;
}


.btn-decline {
    background-color: transparent;
    color: $primary-color;
    border: 0 none;
}

.btn-back {
    border: 1px solid $primary-color;
    color: $primary-color;
    background-color: transparent;
    margin-right: 10px;
}

.normal-width {
    padding: 5px 90px;
    width: 15.8em !important;
}

.btn-next {
    padding: 5px 90px;
}
