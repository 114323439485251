.alternate_header {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
        height: 145px;
        position: relative;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        img {
            margin-top: auto;
            margin-bottom: auto;

}
}
