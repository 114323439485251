.auth {
    width: 90%;
    min-height: 18em;
    margin: 0 auto;

    .striking {
        margin-top: 10px;
        padding: 15px;
        margin-bottom: 10px;
        font-weight: $font-light;
        width: 100%;
    }

    input.striking {
        border: 1px solid darken($primary-color, 10%);
        font-size: 18px;
        padding: 20px;
    }

    .forgot-password {
        text-align: right;

        a {
            font-size: 1rem;
        }
    }

    .buttons {
        text-align: right;
        margin-top: 40px;

        .button {
            display: block;
            margin-top: 0;
            color: $white;
            transition: ease all .2s;
            text-align: center;
            font-size: 1.2rem;

            @include desktop {
                margin-top: 50px;
            }
        }

        @include desktop {
            margin-top: 0;
        }
    }

    @include desktop {
        width: 35em;
    }

    @include mobile {
        width: 100%;
    }
}
