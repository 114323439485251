.basket-standard {
    background: $white;
    padding: 25px;
}

.basket {
    .table {
        .table-head,
        .table-foot {
            background: $light-gray;
        }
    }
}
