.pop-up-form {
    .product {
        padding-top: 20px;
        margin-bottom: 20px;

        img {
            width: 100%;
            height: auto;
        }

        .product-content {
            @include tablet {
                padding-right: 7rem;
            }
        }
    }

    .create-message {
        border: 0 none;
        padding: 10px 70px;
        margin: 30px 0;

        &.closed {
            display: none;
        }
    }

    .ticket-update {
        display: none;
        border: 1px solid $primary-color;
        margin-bottom: 30px;

        form {
            margin-right: 3px;
        }

        &.open {
            display: block;
        }

        .form-footer {
            padding: 20px;
        }

        .send-btn {
            border-radius: 20px;
            border: 0 none;
            padding: 0 55px;
        }

        .cancel {
            padding: 0 45px;
            margin-right: 5px;
            display: inline-block;

            &:hover{
                cursor: pointer;
            }
        }

        .form-header {
            padding: 5px 20px;
            font-size: 18px;
            font-weight: $font-bold;
            width: calc(100% + 1px);
        }

        #file-upload {
            display: none;
        }

        label[for="file-upload"] {
            font-size: 18px;

            &:hover {
                cursor: pointer;
            }
        }

        textarea {
            width: 100%;
            height: 100%;
            border: 0 none;
            margin: 1px;
            border-bottom: 1px solid $gray-background;
            min-height: 200px;
            padding: 20px;
        }
    }

}

.accordion-header{
    .arrow {
        transition: transform .3s ease-in-out;
    }
    &.open {
        .arrow {
            transform: rotateX(180deg);
        }
    }
}

.state {
    display: flex;
    align-items: center;

    .type1,
    .type4,
    .type11,
    .type12,
    .type15,
    .type17,
    .type20,
    .type23,
    .type10 {
        background-color: $created;
        @include ticket-state;
    }

    .type18,
    .type19,
    .type22,
    .type14{
        @include ticket-state;
        background-color: $progress;
    }

    .type16,
    .type2,
    .type13,
    .type21{
        @include ticket-state;
        background-color: $completed;
    }
}


.ticket {
    background-color: $primary-color;
    padding: 10px 5px;
    margin-bottom: 1px;

    .f-big {
        font-size: 23px;
    }

    .state {
        font-size: 17px;
    }

    td:first-child {
        padding: 10px;
    }

    .ticket-articles {
        position: relative;

        label[for="file-upload"] {
            position: absolute;
            bottom: 0;
            left: 15px;
        }

        .ticket-message {
            margin-bottom: 20px;
            word-break: break-word;
        }

        .ticket-content {
            padding-bottom: 45px;

            span {
                margin-bottom: 5px;
            }
        }
    }
}
