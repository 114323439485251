.eleasa-speech-bubble{
  border: 1px solid $black;
  margin: 0 30px;
  position: relative;
  border-radius: 15px;

  &.top:before{
    content: "";
    width: 0px;
    height: 0px;
    position: absolute;
    border-left: 20px solid $black;
    border-right: 20px solid transparent;
    border-top: 20px solid transparent;
    border-bottom: 20px solid $black;
    left: 263px;
    top: -40px;
    @include mobile{
      border-left: 20px solid transparent;
      border-right: 20px solid transparent;
      border-top: 20px solid transparent;
      border-bottom: 20px solid $black;
      left: 50%;
      top: -40px;
    }
  }

  &.top:after{
    content: "";
    width: 0px;
    height: 0px;
    position: absolute;
    left: 264px;
    top: -38px;
    border-left: 20px solid $white;
    border-right: 20px solid transparent;
    border-top: 20px solid transparent;
    border-bottom: 20px solid $white;
    @include mobile{
      border-left: 20px solid transparent;
      border-right: 20px solid transparent;
      border-top: 20px solid transparent;
      border-bottom: 20px solid $white;
      left: 50%;
      top: -39px;
    }
  }

  &.bottom:before{
    content: "";
    width: 0px;
    height: 0px;
    position: absolute;
    border-left: 20px solid $black;
    border-right: 20px solid transparent;
    border-top: 20px solid $black;
    border-bottom: 20px solid transparent;
    left: 263px;
    bottom: -40px;
    @include mobile{
      border-left: 20px solid transparent;
      border-right: 20px solid transparent;
      border-top: 20px solid $black;
      border-bottom: 20px solid transparent;
      left: 50%;
      bottom: -40px;
    }
  }

  &.bottom:after{
    content: "";
    width: 0px;
    height: 0px;
    position: absolute;
    border-left: 20px solid $white;
    border-right: 20px solid transparent;
    border-top: 20px solid $white;
    border-bottom: 20px solid transparent;
    bottom: -38px;
    left: 264px;
    @include mobile{
      bottom: -39px;
      left: 50%;
      border-left: 20px solid transparent;
      border-right: 20px solid transparent;
      border-top: 20px solid $white;
      border-bottom: 20px solid transparent;
    }
  }

  &.left:before{
    content: "";
    width: 0px;
    height: 0px;
    position: absolute;
    border-left: 20px solid transparent;
    border-right: 20px solid $black;
    border-top: 20px solid transparent;
    border-bottom: 20px solid $black;
    top: 26%;
    left: -41px;
    @include mobile{
      border-left: 20px solid transparent;
      border-right: 20px solid transparent;
      border-top: 20px solid transparent;
      border-bottom: 20px solid $black;
      left: 50%;
      top: -40px;
    }
  }

  &.left:after{
    content: "";
    width: 0px;
    height: 0px;
    position: absolute;
    border-left: 20px solid transparent;
    border-right: 20px solid $white;
    border-top: 20px solid transparent;
    border-bottom: 20px solid $white;
    top: 25%;
    left: -39px;
    @include mobile{
      border-left: 20px solid transparent;
      border-right: 20px solid transparent;
      border-top: 20px solid transparent;
      border-bottom: 20px solid $white;
      left: 50%;
      top: -39px;
    }
  }
}
