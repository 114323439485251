header {
    background-color: $white;

    .catalog-filter-search {
        .value {
            border-radius: 15px 0 0 15px;
            border-color: $black !important;
        }

        .reset {
            border-color: $black !important;
        }

        button:last-child {
            border-radius: 0 15px 15px 0;
            border-color: $black;
        }
    }

    .nav-budget {
        background-color: lighten($primary-color, 20%);
        padding: 5px 25px;
        display: inline-block;
        float: right;
        margin: 15px 0 0;
        border-radius: 10px;

        &:hover {
            background-color: lighten($primary-color, 20%);;
        }

        span {
            text-transform: none;
            color: $white;
        }
    }

    &.header {
        & > .container {
            @include mobile {
                padding-top: 0;
            }
        }

        .header--logo {
            img {
                @include mobile {
                    max-width: 85px;
                }
            }
        }
    }
}
