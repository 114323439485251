.order-announcement {
    @include mobile {
        margin-top: 10px;
    }
    padding: 22px;
    margin-top: 10px;
    border: 1px solid $primary-color;
    display: flex;
    justify-content: center;
    width: 100%;

    .mpp-text {
        color: $primary-color;
        font-size: 18px;

        .mppdate {
            padding: 0 3px;
            font-weight: $font-bold;
        }
    }
}

.a-left {
    width: 35px;
    height: 35px;
    background-color: $secondary-color;
    border-radius: 50%;
    opacity: .7;
    position: absolute;
    z-index: 2;
    top: 50%;
    transform: translateY(-50%);
    left: 1em;
    transition: .3s opacity ease-in;

    &:hover {
        opacity: 1;
        cursor: pointer;
    }

    &:after {
        content: "";
        @extend .arrow-left-white--icon;
        display: inline-block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 35px;
        height: 35px;
    }

    @include mobile {
        width: 25px;
        height: 25px;
        &:hover {
            opacity: 1;
        }
        &:after {
            width: 25px;
            height: 25px;
        }
    }
}

.a-right {
    width: 35px;
    height: 35px;
    background-color: $secondary-color;
    border-radius: 50%;
    opacity: .7;
    position: absolute;
    z-index: 2;
    top: 50%;
    transform: translateY(-50%);
    right: 1em;
    transition: .3s opacity ease-in;

    &:hover {
        opacity: 1;
        cursor: pointer;
    }

    &:after {
        content: "";
        @extend .arrow-right-white--icon;
        display: inline-block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 35px;
        height: 35px;
    }

    @include mobile {
        width: 25px;
        height: 25px;
        &:hover {
            opacity: 1;
        }
        &:after {
            width: 25px;
            height: 25px;
        }
    }
}

.slider {
    width: 100%;

    #homepage-slider {
        max-width: 100%;

        img {
            max-width: 100%;
        }

        .slick-slide {
            width: 100%;

            img {
                width: 100%;
            }
        }

        @include tablet {
            margin-top: -30px;
        }
    }
}

#homepage-slider-mobile {
    max-width: 100%;

    img {
        width: 100%;
    }
}

.tile {
    border: 1px solid $primary-color;
    padding: 17px 20px 20px 20px;
    height: 100%;

    img {
        text-align: right;
    }

    .tiles-header {
        h3 {
            letter-spacing: -0.71px;
            line-height: 28px;
            font-weight: $font-bold;
            text-transform: none;
            font-size: 24px;
        }
    }

    .icon {
        height: 80px;
        width: 80px;
        display: inline-block;
        margin: 0;
    }

    .tiles-text {
        min-height: 63px;
        font-size: 18px;

        @include mobile {
            margin-top: 15px;
        }
    }

    .tiles-button {
        background-color: $primary-color;
        color: $white;
        border: none;
        border-radius: 30px;
        height: 29px;
        padding: 2px 35px 27px 35px;
        font-weight: $font-light;
        text-align: center;

        @include mobile {
            white-space: nowrap;
            width: 100%;
            display: block;
            height: auto;
            padding: 5px 25px;
            bottom: 0;
        }
    }

    .img-holder {
        text-align: right;
    }

    @include mobile {
        padding-bottom: 20px;
    }
}

.three-tiles {
    margin-top: 60px;
    margin-bottom: 100px;
    width: 100%;
    @include mobile {
        margin-top: 13px;
        margin-bottom: 40px;
    }
}

.service-tile {
    background-color: $primary-color;
    margin-top: 60px;
    padding: 22px;

    a:hover {
        color: $primary-color;
    }

    h4, p {
        color: $white;
    }

    .service-button {
        background-color: $white;
        border: 0;
        border-radius: 30px;
        height: 24px;
        padding: 0 30px 27px 30px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
    }
}

.top-products {
    position: relative;
    width: 100%;

    h2.headline {
        text-transform: uppercase !important;
        color: $primary-color;
        display: inline-block;
        background-color: $white;
        position: absolute;
        left: 50%;
        font-weight: $font-regular;
        transform: translateX(-50%);
        top: 0;
        padding: 0 16px;

        @include mobile {
            top: -36px;
        }
    }

    hr {
        border-color: $primary-color;
    }
}

.brand-slider {
    margin-top: 100px;
    position: relative;
    width: 100%;
    @include mobile {
        margin-top: 60px;
    }

    .headline-wrapper {
        width: 100%;
        height: 8vh;
        position: relative;

        h2.headline {
            text-transform: uppercase !important;
            color: $primary-color;
            display: inline-block;
            background-color: $white;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            top: -19px;
            padding: 0 16px;
            @include mobile {
                top: -50%;
                text-align: center;
            }
        }

        hr {
            border-color: $primary-color;
        }
    }

    .slick-track {
        display: flex;
        justify-content: center;
        align-items: center;

        .slick-slide {
            .item {
                justify-content: center;
                align-items: center;
                height: 100%;

                img {
                    max-width: 50%;
                    width: 50%;
                    margin: 0 auto;
                }
            }
        }
    }
}

.tile-wrapper {
    @include mobile {
        margin-bottom: 10px;
    }
}

.initializing {
    display: none !important;
}
