.insurance-container {
    display: none;
    position: fixed;
    background: rgba(0,0,0,0.5);
    z-index: 999;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;

    &.show {
        display: block;
    }

    .insurance-content {
        width: 70%;
        max-height: 80vh;
        background: $white;
        position: absolute;
        top: 10%;
        left: 50%;
        transform: translateX(-50%);
        padding: 40px;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;

        p {
            display: block;
            margin-left: 0;
        }

        .info-box {
            margin: 0 0 40px 0;
            overflow: auto;
        }

        .embed-responsive {
            width: 90% !important;
            margin: 0 auto;

            @include mobile {
                overflow: auto;
                -webkit-overflow-scrolling: touch;
            }
        }

        .insurance-button-group {
            text-align: center;
            margin-top: 50px;
            padding-bottom: 20px;

            button {
                font-family: $font-family-sans-serif;
                color: $white;

                @include mobile {
                    margin-bottom: 15px;
                    width: 100%;
                }
            }
        }

        @include mobile {
            width: 95%;
            padding: 25px;

            .headline {
                font-size: 18px;
            }

            .subheadline {
                font-size: 16px;
            }
        }
    }
}
