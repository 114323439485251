.aimeos.checkout-confirm {
    margin: 50px 0;

    svg {
        width: 100%;
        margin: 0 0 30px 0;
    }

    .confirmation-intro {
        margin-bottom: 30px;
    }
    .btn{
        margin-top: 15px;
        font-size: 20px;
        margin-bottom: 25px;
    }
    .btn-primary {
        color: $white;
    }
    .checkout-confirm-retry{
        margin-top: 40px;
    }
}
