.catalog-stage-breadcrumb {

    ol {
        margin: 0;
        padding: 0;
    }

    .title {
        margin-right: 10px;
    }

    li {
        white-space: inherit;
        &:after{
            content: " － ";
        }
    }
}
