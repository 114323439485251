.calculator-summary {
    font-size: 17px;

    .calculation-information {
        margin-top: 40px;

        h2 {
            margin-bottom: 50px;

            @include mobile {
                margin-bottom: 30px;
            }
        }

        .row {
            .col-12 {
                @include mobile {
                    align-items: center;
                    justify-content: center;
                    text-align: center;
                }
            }

            &:not(.striped).bold {
                @include mobile {
                    font-weight: $font-regular;
                    font-size: 15px;
                }
            }
        }
    }

    .summary {
        margin: 60px 0 30px 0;

        .row {
            .col-6,
            .col-12 {
                @include mobile {
                    align-items: center;
                    text-align: center;
                    justify-content: center;
                }
            }

            .col-12 {
                @include mobile {
                    margin-bottom: 8px;

                    & + .col-12 {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }

    hr.mobile {
        display: none;

        @include mobile {
            display: block;
        }
    }

    .striking {
        @include mobile {
            font-size: 25px;
        }
    }

    @include mobile {
        font-size: 16px;
    }
}
