.catalog-detail {
    margin-top: 30px;

    .caption {
        &.description {
            display: none;
        }
    }

    .header {
        padding: 0 1em;
    }

    .delivery-info {
        font-size: 12px;
    }

    .calculator-teaser {
        border-bottom: 1px dashed $light-gray;
        border-top: 1px dashed $light-gray;
        padding: 50px 0;
        display: flex;

        a {
            display: flex;
            align-items: center;
            background: $primary-color;
            color: $white !important;
            font-size: 18px;
            width: 100%;
            justify-content: center;
            padding: 15px;

            &:hover {
                background: darken($primary-color, 10%);
            }

            .edit--icon {
                display: block;
                width: 25px;
                height: 25px;
                margin: 0 10px;
            }
        }
    }

    .catalog-detail-basic {
        h1 {
            &.name {
                font-size: 22px;
                color: $dark-gray;
                text-transform: inherit;
                font-weight: 700;
                height: auto;
                margin-bottom: 5px;
            }
        }

        .short {
            display: block;
        }
    }

    .catalog-detail-additional {
        margin-top: 50px;

        .header {
            position: relative;

            .plus,
            .minus {
                height: 20px;
                width: 20px;
                position: absolute;
                right: 5px;
                top: 50%;
                transform: translateY(-50%);
            }

            .plus {
                display: block;
            }

            .minus {
                display: none;
            }

            &:after {
                content: "";
            }

            &.opened {
                .minus {
                    display: block;
                }

                .plus {
                    display: none;
                }
            }

            @include mobile {
                padding-left: 5px;
            }
        }
    }

    .catalog-detail-image {
        figcaption {
            display: none;
        }

        .bu-image-single,
        .image-single {
            height: 400px;

            .media-item {
                height: 400px;
            }
        }

        .thumbs-horizontal {
            .slick-next {
                position: relative;
                background-image: none;

                svg {
                    width: 40px;
                    height: 40px;
                    position: absolute;
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }

            .slick-prev {
                position: relative;
                background-image: none;

                svg {
                    width: 40px;
                    height: 40px;
                    right: 0;
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }
        }
    }

    .catalog-detail-basket {
        .price-list {
            text-align: right;
            background-color: unset;
            color: $dark-gray;
            font-weight: $font-regular;
            padding-top: 0;
            font-size: 1rem;

            .value {
                font-size: 28px;
                font-weight: $font-bold;
            }
        }

        .all-round-protection {
            display: flex;
            margin-top: 35px;

            svg {
                width: 40px;
                height: 40px;
                display: inline-block;
                margin-right: 5px;
            }

            .all-round-protection--text {
                display: inline-block;
            }

            a {
                display: flex;
                align-items: center;
                margin-left: auto;
                color: $primary-color;
            }
        }

        .stock-list {
            text-align: left;

            .stockitem {
                display: block;
                margin: auto 0;

                .stock-level--text,
                .stocktext {
                    display: inline-block;
                    font-weight: $font-bold;
                }

                .stock-level--text {
                    color: $dark-gray;
                }

                .stocktext {
                    margin-left: 5px;
                }
            }

        }

        .addbasket {
            margin-top: 2.5em;
            font-weight: $font-regular;

            button {
                width: 100%;
                padding: 10px 50px;
                margin: 0 0 0 auto;

                @include tablet {
                    width: 50%;
                }
            }
        }
    }
}

.catalog-detail--stage {
    border-bottom: 1px dashed $light-gray;

    .catalog-stage-breadcrumb {
        display: inline-block;
    }

    .catalog-stage-image {
        display: none;
    }

    .catalog-stage-navigator {
        display: inline-block;
        float: right;

        nav {
            display: flex;
            align-items: center;

            .next {
                position: relative;
                font-size: 1rem;
                display: flex;
                align-items: center;

                &:after {
                    content: "";
                }

                .arrow-right {
                    height: 25px;
                    width: 25px;
                }
            }

            .prev {
                position: relative;
                font-size: 1rem;

                &:before {
                    content: "";
                }

                .arrow-left {
                    height: 25px;
                    width: 25px;
                }
            }
        }

        @include mobile {
            float: none;
        }
    }

    @include mobile {
        text-align: center;
        padding-bottom: 30px;
    }
}

.catalog-detail {
    .product {
        .price-list {
            display: flex;
            justify-content: flex-end;

            .price-actual {
                width: 100%;

                .netto-text {
                    font-weight: $font-regular;
                    font-size: 14px;
                }

                .price-text {
                    .value {
                        font-weight: 400;
                        font-size: 46px;
                        margin: 0;
                        display: inline;
                        vertical-align: middle;

                        #loader-dots {
                            &.isloading {
                                span {
                                    background-color: $black;
                                }
                            }
                        }
                    }
                }

                .currency {
                    font-size: 46px;
                    vertical-align: middle;
                }

                .ordernow__button-text {
                    display: none;
                }
            }

            &:hover {
                .price-text {
                    margin-top: 0;
                }
            }
        }

        .calculate {
            &.btn-primary--rounded {
                padding: 0;
                width: 25%;
                color: $white;
                font-size: 17px;
                @include mobile {
                    width: 100%;
                }
            }
        }

        .info-small {
            width: 30px;
            height: 30px;
            display: block;
        }

        .catalog-detail-basket {
            .stock-high,
            .stock-unlimited {
                color: #89C882;
            }
        }
    }

    .content {
        &.attributes {
            display: flex;
            max-width: 100%;
            flex-wrap: wrap;
            align-items: flex-start;

            .item {
                width: 25%;

                #attrValue_1 {
                    word-break: break-all;
                }
            }
        }

        .attr-headline {
            color: $primary-color;
            text-decoration: underline;
            font-weight: $font-bold;
        }
    }

    .catalog-detail-image {
        .thumbs-horizontal {
            .thumbs {
                width: calc(100% - 87px);
            }
        }
    }
}

.detail-service {

    padding-bottom: 20px;
    text-align: left;

    .servive-header {
        display: flex;
        padding-bottom: 15px;
        align-content: baseline;

        span {
            align-self: center;
            font-weight: bold;
            font-size: 20px;
            line-height: 24px;
        }

    }

    .btn {
        border-radius: 25px;
        width: auto;
        font-size: 16px;
        margin-right: 15px;
    }

    .info {
        align-self: center;
        width: 25px;

        .info-text.left {
            font-size: 16px;
            left: -238px;
        }
    }
}
