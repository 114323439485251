.splitter {
    display: block;
    margin: 5px 0;
}

.info-box {
    padding: 15px;
    border: 1px solid $secondary-color;

    &.max-height{
        height: 100%;
    }

    &.with-button {
        padding-bottom: 70px;
        position: relative;
    }

    .info-box--header {
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        h3{
            font-size: 30px;
            line-height: 34px;
            margin: 0;
            text-transform: capitalize;
        }
        .icon {
            display: inline-block;
            width: 40px;
            height: 40px;
            margin-right: 5px;
            &.icon-big{
                width: 60px;
                height: 60px;
                margin-right: 10px;
            }
        }

        .info-headline {
            font-size: 21px;
            font-weight: $font-bold;
            display: inline-block;

            @include mobile {
                font-size: 18px;
            }
        }
    }

    .info-box--body {
        p {
            margin-bottom: 0;
            line-height: 20px;

            @include mobile {
                font-size: 13px;
            }
        }
    }
    .button-row {
        position: absolute;
        bottom: 15px;
        margin: 0 -15px;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
    }
}

@mixin pseudos {
    &:hover,
    &:focus,
    &:active {
        @content;
    }
}

@mixin noselect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
}

@mixin icon-sizes {
    min-width: 20px;
    min-height: 20px;
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
}

@mixin rounded{
    border-radius: 20px;
    overflow: hidden;
}

@mixin ticket-state {
    border-radius: 50%;
    width: 15px;
    height: 15px;
    margin-right: 10px;
}
