#cookieconsent {
    z-index: 999;
    position: fixed;
    width: 100%;
    background: rgba(0,0,0,0.9);
    left: 0;
    right: 0;
    bottom: 0;
    padding: 35px 25px;
    color: #fff;

    p {
        margin: 0;
    }

    a{
        color: $white;
        text-decoration: none;
        @include pseudos{
            text-decoration: none;
            color: $white;
        }
    }

    &.fadeOutOpacity {
        pointer-events: none;
        animation: fadeOutOpacity .3s ease forwards;
    }
}

@keyframes fadeOutOpacity {
    to {
        opacity: 0;
    }
}
