.account-history-list {
    .garantie {
        height: 45px;
        width: 45px;
    }

    .history-list {
        margin-top: 40px;

        .history-item {
            border: 0 none;
            margin: 1rem 0;
            display: block;

            .history-header {
                position: relative;
                display: block;
                width: 100%;
                background: $primary-color;
                color: $white;
                text-transform: uppercase;
                text-align: left;
                padding: 15px;
                font-weight: $font-light;
                font-size: 23px;
                line-height: 21px;
                border: 1px solid $primary-color;
                cursor: pointer;

                .show-details {
                    vertical-align: middle;
                    float: right;
                    cursor: pointer;
                    position: absolute;
                    right: 15px;
                    padding: 8px 30px;
                    margin: 10px 0;
                    line-height: 1;
                    background: #fff;
                    top: 0;
                    background: $white;

                    & * {
                        cursor: pointer;
                    }

                    .show-details--text {
                        color: $primary-color;
                        display: block;
                        font-size: 1rem;
                        text-transform: none;
                        @include mobile {
                            display: none;
                        }
                    }
                }

                &:hover {
                    color: $white;
                }

                @include mobile {
                    font-size: 18px;
                }
            }

            .history-body {
                border-top: 0;
                padding: 30px 0;

                & > .row {
                    margin-left: 0;
                    margin-right: 0;
                }

                .attr-item {
                    .name {
                        font-weight: $font-bold;
                    }

                }
            }

            .basket {
                width: 100%;
                margin: 0;

                a {
                    color: $primary-color;
                }

                .products {
                    border-bottom: 1px solid $primary-color;
                    margin-bottom: 60px;
                }

                .product {
                    position: relative;
                    padding: 0;
                    margin: 0;

                    p {
                        margin-bottom: 0;
                    }

                    .product-row {
                        padding: 20px 0;
                        border: 1px solid $primary-color;
                        border-bottom: 0;
                        margin: 0;
                        align-items: center;
                    }

                    &.disabled {

                        .details {
                            opacity: 0.7;
                        }
                    }

                    .details {

                        &.details-product {
                            display: flex;
                            align-items: center;

                            img {
                                float: left;
                                max-width: 6em;
                                max-height: 8em;
                                overflow: hidden;
                                margin-right: 5%;

                                @include mobile {
                                    width: 100%;
                                    max-height: 100%;
                                    max-width: 7em;
                                    float: none;
                                    margin: 0 auto;
                                    display: block;
                                }
                            }
                        }

                        @include mobile {
                            margin: 20px 0;
                        }
                    }

                }
            }

            .common-summary-detail {
                margin-top: 40px;

                .header {
                    padding: 10px 0;
                    margin: 0;
                }

                @include mobile {
                    margin-top: 25px;
                }
            }

            .common-summary-address {
                .contract-data {
                    span {
                        display: block;
                    }

                    .user-licensing-agreement {
                        margin-top: 15px;
                    }

                    @include mobile {
                        margin-bottom: 20px;
                    }
                }

                .order-question {
                    display: flex;
                    margin: 30px 0 0 0;
                    align-items: center;

                    a {
                        font-weight: $font-regular !important;
                        color: $white !important;

                        @include pseudos {
                            color: $white !important;
                        }
                    }

                    @include mobile {
                        font-size: 15px;
                        margin-top: 20px;
                    }
                }

                .order-tracking {
                    margin-top: 25px;

                    .order-tracking-number {
                        font-weight: $font-bold;
                        color: $primary-color;
                        font-size: 18px;
                    }
                }

                .contract-data,
                .order-tracking,
                .payment {
                    .header {
                        padding: 0 0 10px 0;
                        margin: 0;
                        border-bottom: 1px solid $light-gray;
                    }

                    .content {
                        padding: 15px 0;
                        margin: 0;

                        a {
                            color: $primary-color;
                            font-weight: $font-bold;
                        }
                    }
                }
            }
        }
    }

    /* ORDER REPORTS */

    .order-report-button {
        display: flex;
        justify-content: center;

        &:before {
            content: "\2039";
            color: #ffb729;
            transform: rotate(270deg);
            display: inline-block;
            font-size: 50px;
            margin-right: 15px;
        }

        @include mobile {
            margin: 15px 0;
        }
    }

    .order-reports {
        margin-top: 35px;
        margin-bottom: 5px;
        text-align: center;
        display: none;
        border-top: 1px dotted $gray-border;
        padding: 10px 0;

        .order-report {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 18px;
            font-weight: $font-bold;

            .transport {
                display: inline-block;
                height: 50px;
                width: 50px;
                margin-right: 10px;
            }

            .theft {
                display: inline-block;
                height: 50px;
                width: 50px;
                margin-right: 10px;
            }

            .damage {
                display: inline-block;
                height: 50px;
                width: 50px;
                margin-right: 10px;
            }

            @include mobile {
                justify-content: flex-start;
                font-size: 13px;
                margin: 10px 0;
            }
        }

        @include mobile {
            margin-top: 30px;
        }
    }

    .opened {
        display: block;
    }

    .ueberblick {
        margin-right: 10px;
    }
}
.aimeos {
    .bu-account-history-order {
        .header {
            padding: 0 0 10px 0;

            h3 {
                margin: 0;
            }
        }
        .common-summary-detail {
            .header-underline {
                border-bottom: 1px solid #3B3B3B !important;
                margin-bottom: 10px;
            }
        }
        .common-summary {
            .header {
                border-bottom: 1px solid #3B3B3B !important;
                padding: 0 0 10px 0;
            }
        }

        .basket {
            .package {
                .header {
                    border: 0 none;
                }
            }
        }
    }
    .account-history-list {
        .history-list {
            .history-item {
                .common-summary-address {
                    .contract-data {
                        .header {
                            border-bottom: 1px solid #3B3B3B !important;
                        }
                    }
                }
            }
        }

    }
}

.common-summary-address {
    .contract-data {
        padding-top: 49px;
    }

}

.common-summary-delivery {
    .item {
        &.contact,
        &.payment {
            .content {
                padding: 15px 0;
                margin: 0;
            }
            .header {
                border-bottom: 1px solid #3B3B3B;
                margin: 0;
            }
        }
    }
}

.common-summary-detail {
    .basket {
        .order-tracking {
            .content {
                margin: 0;
                padding: 0 0 10px 0;
            }
            .row {
                padding-top: 5px;
            }
        }
    }
}

.deliverynumber {
    background-color: $gray-background;
    padding: 10px 12px;
    margin-bottom: 10px;
}
