.account-profile-address{
    margin-bottom: 30px;
}

.service-summary-delivery {
    .item-address {
        height: 100%;
        border: 0 none;

        .header {
            height: 100%;

            h3 {
                font-weight: $font-regular;
            }
            .modify {
                background-color: $primary-color;
                color: $white;
                border-radius: 15px;
                padding: 3px 20px;
            }
        }

        .customer-info-item {
            font-size: 17px;
        }

        .header,
        .content {
            background: transparent;
            padding: 20px 60px;
            margin: 0;
        }
    }

    #ca_mpp_deliveryoption-null {
        margin-right: 25px;
    }

    .account--data__item {
        .btn{
            border: 0 none;
            padding: 5px 20px 30px 0;
            font-size: 17px;
        }
    }
}


.button-group {
    display: flex;
}

.space-between {
    display: flex;
    justify-content: space-between;
}
