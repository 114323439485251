.all-around-protection {
    .protection-head {
        padding: 15px;
        background-color: $white;
        margin-bottom: 20px;

        .icon {
            width: 100%;
            height: 320px;
        }

        .headlines {
            padding: 0;
            display: flex;
            align-items: flex-end;
            a{
                text-decoration: underline;
                color: #000;
            }
            span {
                font-size: 15px;
                padding-bottom: 15px;
                padding-left: 15px;

                @include desktop {
                    font-size: 21px;
                }
            }

            @include mobile {
                margin-bottom: 15px;
            }
        }
    }

    .calculation-container {
        display: flex;
        justify-content: center;
        align-items: center;
        @include rounded;
        margin-top: 20px;
        padding: 20px;
        background-color: $white;

        img {
            width: 100px;
        }

        .calculator-content {
            span {
                font-size: 30px;
                font-weight: $font-bold;
            }
        }

        .calculator-content--button {
            .calculator-btn {
                background-color: $primary-color;
                padding: 10px 45px;
                text-align: center;
                border-radius: 10px;

                span {
                    color: $white;
                    font-weight: $font-bold;
                }
            }
        }
    }

    .eleasa-security-content {
        background-color: $white;
        padding: 40px;
        margin-top: 20px;
        @include rounded;

        .eleasa-headline {
            img {
                width: 60%;
            }
        }

        .eleasa-img {
            img {
                height: 100%;
                width: 100%;
            }
        }
    }

    .eleasa-info-box {
        background-color: $white;
        margin: 30px 0 10px 0;
        line-height: 14px;
        padding: 20px 20px 35px 30px;

        a {
            color: $primary-color;
        }

        span {
            font-size: 12px;
        }
    }

    .eleasa-security-content {
        .content-block {
            margin: 10px 0;
            line-height: 18px;
        }
    }

    .icon {
        &.eleasagadgets {
            width: 100%;
            height: 100%;
        }

        &.taschenrechner {
            width: 60px;
        }
    }

    .content-tile-image-container {
        svg {
            width: 150px;
            height: 250px;
        }
        .icon{
            &.garantieverlaengerung {
                width: 240px;
            }
            &.vandalismuspc{
                width: 200px;
            }
        }
    }
}
