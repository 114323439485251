.checkout-standard {
    padding: 25px;
    background: $white;
}
.accessories-text,
.basket-button {
    display: none;
}

.accessories-text {
    line-height: 36px;
    padding: 0 10px;
}


.aimeos-container .accessories-text {
    display: flex;
}

.aimeos-container .basket-button {
    display: block;

}

.aimeos-container .checkout-button {
    display: none;
}
