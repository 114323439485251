.footer {
    padding: 25px 0;
    background-color: $light-gray;

    h2 {
        font-size: 24px;
        text-transform: uppercase;
    }

    a {
        color: $primary-color;
    }

    ul {
        padding-left: 0;
        margin-bottom: 0;
        list-style: none;
    }

    .fb-icon {
        background-image: url('../images/FB-fLogo_weiss.svg');
        width: 43px;
        height: 43px;
        display: inline-block;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: 50% 50%;
    }

    .fb-link-text {
        display: inline-block;
        font-size: 17px;
        font-weight: lighter;
        line-height: 20px;
        text-transform: none;
        padding-left: 15px;
    }

    .fb-link{
        display: flex;
    }
    .yammer-icon{
        width: 40px;
        height: 40px;
    }

    @include mobile {
        margin-top: 50px;
    }
    .footer-menu--item{
        padding-bottom: 10px;
    }
}
