.calculator {
    .isloading {
      position: fixed;
      overflow: hidden;
      width: 100%;
      height: 100%;
    }
}


.pop-up {
    #loader-dots {
        display: none;

        &.isloading {
            display: flex;
            justify-content: center;

            span {
                background-color: $black !important;
            }
        }
    }
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}
@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
