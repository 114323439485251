.breadcrumb-navi {
    margin-bottom: 60px;
    font-weight: $font-bold;
    border-bottom: 1px dotted $gray-border;
    padding-bottom: 30px;
    .arrow-left{
        margin-right: 10px;
    }
    .text-left {
        position: relative;

        a{
            font-size: 17px;

        }

        p {
            display: inline-block;
        }

        .icon {
            width: 30px;
            height: 30px;
        }
    }
}
