@font-face {
    font-family: "Bradford Sub Medium";
    src: url("../fonts/bradford/BradfordLLSub-Medium.eot");
    src: url("../fonts/bradford/BradfordLLSub-Medium.woff") format("woff"),
    url("../fonts/bradford/BradfordLLSub-Medium.woff2") format("woff2");
}

@font-face {
    font-family: "Bradford Medium";
    src: url("../fonts/bradford/BradfordLLWeb-Medium.eot");
    src: url("../fonts/bradford/BradfordLLWeb-Medium.woff") format("woff"),
    url("../fonts/bradford/BradfordLLWeb-Medium.woff2") format("woff2");
}

@font-face {
    font-family: "Bradford Sub Medium Italic";
    src: url("../fonts/bradford/BradfordLLSub-MediumItalic.eot");
    src: url("../fonts/bradford/BradfordLLSub-MediumItalic.woff") format("woff"),
    url("../fonts/bradford/BradfordLLSub-MediumItalic.woff2") format("woff2");
}

@font-face {
    font-family: "Bradford Medium Italic";
    src: url("../fonts/bradford/BradfordLLWeb-MediumItalic.eot");
    src: url("../fonts/bradford/BradfordLLWeb-MediumItalic.woff") format("woff"),
    url("../fonts/bradford/BradfordLLWeb-MediumItalic.woff2") format("woff2");
}


@font-face {
    font-family: 'Neuzeit Bold';
    src: url('../fonts/neuzeit/38B08A_0_0.eot');
    src: url('../fonts/neuzeit/38B08A_0_0.eot?#iefix') format('embedded-opentype'),
    url('../fonts/neuzeit/38B08A_0_0.woff2') format('woff2'),
    url('../fonts/neuzeit/38B08A_0_0.woff') format('woff'),
    url('../fonts/neuzeit/38B08A_0_0.ttf') format('truetype');
}


@font-face {
    font-family: 'Neuzeit Regular';
    src: url('../fonts/neuzeit/38B08A_1_0.eot');
    src: url('../fonts/neuzeit/38B08A_1_0.eot?#iefix') format('embedded-opentype'),
    url('../fonts/neuzeit/38B08A_1_0.woff2') format('woff2'),
    url('../fonts/neuzeit/38B08A_1_0.woff') format('woff'),
    url('../fonts/neuzeit/38B08A_1_0.ttf') format('truetype');
}

