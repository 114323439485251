.pagination {
    margin: 30px 0 60px 0;

    .browser,
    .limit,
    .sort {
        margin: 0 0 15px 0;

        @include mobile {
            max-width: 100%;
        }
    }

    .limit,
    .sort {
        text-align: left;
        width: auto;
        position: relative;
        display: inline-block;
        @include noselect;

        .limit-select,
        .sort-select {
            overflow: hidden;
            border: 1px solid $light-gray;
            height: 45px;
            padding: 10px 15px;
            cursor: pointer;
            width: auto;
            display: inline-flex;

            .arrow-down {
                width: 20px;
                height: 20px;
                display: inline-block;
            }

            .value {
                margin-left: 5px;
                font-weight: $font-bold;
            }
        }

        .limit-select-content,
        .sort-select-content {
            display: none;
            border: 1px solid $light-gray;
            position: absolute;
            right: 0;
            top: 44px;
            z-index: 15;
            background: #fff;
            text-align: center;
            width: auto;
            border-top: 1px solid #fff;
            line-height: 30px;
        }

        ul {
            display: inline-block;
            padding: 0;
            margin: 0;
            list-style: none;

            .active {
                font-weight: $font-bold;
            }
        }
    }

    .browser-container {
        display: flex;
        align-items: center;
        justify-content: center;

        .browser {
            border: 1px solid $light-gray;
            height: 45px;
            align-items: center;
            justify-content: center;
            display: inline-flex;

            span {
                border-right: 1px solid $light-gray;
                padding: 10px 15px;

                @include mobile {
                    padding: 15px 10px;
                    font-size: 12px;
                }
            }

            a {
                border-right: 1px solid $light-gray;
                padding: 10px 15px;

                &:last-child {
                    border: none;
                }

                @include mobile {
                    padding: 10px 10px;
                }
            }

            .next,
            .prev {
                display: inline-flex;
                align-items: center;

                span {
                    border: none;
                    padding: 0;
                }

                @include mobile {
                    span.name {
                        display: none;
                    }
                }
            }

            .next {
                padding-right: 5px;

                .arrow-right {
                    height: 20px;
                    width: 20px;
                }

                @include mobile {
                    padding-right: 10px;
                }
            }

            .prev {
                padding-left: 5px;

                .arrow-left {
                    height: 20px;
                    width: 20px;
                }

                @include mobile {
                    padding-left: 10px;
                }
            }

            .current {
                font-weight: $font-bold;
            }
        }
    }

    .limit-container {
        display: flex;
        align-items: center;
        justify-content: center;

        .limit {
            .limit-select-content {
                padding: 10px 35px 10px 35px;
                width: 100%;
            }
        }
    }

    .sort-container {
        display: flex;
        align-items: center;
        justify-content: center;

        .sort {
            .sort-select-content {
                padding: 10px 15px 10px 15px;
                text-align: left;

                li {
                    display: inline-flex;
                    align-items: center;

                    .arrow-down,
                    .arrow-up {
                        display: inline-block;
                        width: 25px;
                        height: 25px;
                    }
                }
            }
        }
    }

    @include desktop {
        .sort-container {
            justify-content: flex-end;
        }
        .browser-container {
            justify-content: flex-start;
        }
        .limit-container {
            justify-content: flex-end;
        }
    }
}
