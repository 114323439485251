$progress-remaining-color: #89C882;
$progress-used-color: #A10000;

.account-profile-budget-view {
    margin: 80px 0;

    &.disabled {
        .budget-view-header,
        .budget-view-progress,
        .budget-view-progress-values {
            display: none;
        }
    }

    h2 {
        font-weight: $font-regular;
    }

    .budget-view-header {
        background: transparent;
        border: 1px solid $gray-border;
        margin-bottom: 10px;
        padding: 8px 15px;
        font-weight: $font-bold;
        text-align: center;
        font-size: 18px;
    }

    .budget-view-progress {
        .progress-bar {
            width: 100%;
            position: relative;
            background: $progress-remaining-color;
            height: 10px;
            margin-bottom: 10px;

            span.progress {
                position: absolute;
                background: $progress-used-color;
                top: 0;
                bottom: 0;
                left: 0;
                height: auto;
                max-width: 100%;
                border-radius: 0;
            }
        }
    }

    .budget-view-progress-values {
        .progress-values {
            font-size: 19px;
            font-weight: $font-bold;

            &.remaining {
                color: $progress-remaining-color;
                text-align: right;
            }
            &.used {
                color: $progress-used-color;
                text-align: left;
            }
        }
    }
}
