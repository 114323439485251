
// Body
$body-bg: #f8fafc;

// Colors
$dark-gray: #3b3b3b;
$gray: #d2d2d2;
$light-gray: #F0EFF0;

$controls-gray-hover: #8D8D8D;
$controls-gray: #F0EFF0;
$dark-gray-font: #3B3B3B;
$gray-border: #D5D5D5;
$gray-background: #E3E3E3;

$gray-nav: #E3E3E3;

$gray-nav: #E3E3E3;

$gray-step-color: #d3dbde;

$body-background: #ececed;
$danger-red: #ff321b;

$success-green: #03D138;

// Ticket state colors
$created: #6DA0BE;
$completed: #89C882;
$declined: #a60015;
$progress: #E8A541;

// Typography
$font-family-sans-serif: "Source Sans Pro", sans-serif;
$font-family-headline: "Bradford Medium", sans-serif;
$font-family-text: "Neuzeit Regular", sans-serif;

$font-regular: 400;
$font-bold: 700;
$font-light: 200;

$black: #000;
$white: #fff;

$error-color: #f44336;
$success-color: #4BB543;


$portal-background-gray: #ececed;
$intro-background-gray : $portal-background-gray;
$portal-space-between-elements: 100px 0;
$intro-space-between-elements: $portal-space-between-elements;

// Mobile first breakpoints
$breakpoint-sm: 48em;
$breakpoint-lg: 64em;
$breakpoint-xl: 75em;

@mixin mobile {@media (max-width: 767px) {@content;}}
@mixin tablet {@media (min-width: $breakpoint-sm) {@content;}}
@mixin desktop {@media (min-width: $breakpoint-lg) {@content;}}
@mixin cinema {@media (min-width: $breakpoint-xl) {@content;}}
