.pagination {
    padding: 25px;

    &:last-child {
        .limits {
            display: none;
        }
    }

    .limit,
    .sort {
        ul {
            width: 100%;

            li {
                width: 100%;
                padding: 5px 15px;
                margin-bottom: 3px;
                font-size: 14px;

                a {
                    display: block;
                }

                svg.white {
                    display: none !important;
                }

                &.active {
                    background: $primary-color;

                    a {
                        color: $white;
                    }

                    svg.white {
                        display: inline-block !important;
                    }

                    svg.primary {
                        display: none !important;
                    }
                }

                &:hover {
                    background: $primary-color;

                    a {
                        color: $white;
                    }

                    svg.white {
                        display: inline-block !important;
                    }

                    svg.primary {
                        display: none !important;
                    }
                }

            }
        }

        .limit-select,
        .sort-select {
            border-color: $primary-color;
            height: 40px;
            padding: 10px 15px;
            border-radius: 10px;
            align-items: center;
            font-size: 14px;
            color: $primary-color;

            @include mobile {
                width: 100%;
                display: block;
                text-align: center;
            }
        }

        .limit-select-content,
        .sort-select-content {
            border-color: $primary-color;
            top: 30px;
            border-top: none;
            border-radius: 0 0 10px 10px;
            width: 100%;
            padding: 10px 0 5px 0 !important;
        }

        @include mobile {
            margin-bottom: 5px;
            width: 100%;
            text-align: center;
        }
    }

    .browser-container {
        .browser {
            border-color: $primary-color;
            border-radius: 10px;
            height: 40px;

            a,
            span {
                border-color: $primary-color;
                font-size: 14px;
                padding: 10px;
                color: $primary-color;
                line-height: 18px;
            }

            @include mobile {
                width: 100%;
                margin-bottom: 10px;

                a,
                span {
                    padding: 10px 7px;
                }

                .prev {
                    width: 100%;
                    display: inline-flex;
                    justify-content: flex-start;
                }

                .next {
                    width: 100%;
                    display: inline-flex;
                    justify-content: flex-end;
                }
            }
        }
    }
}
