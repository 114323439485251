input[type=text]:not(.portal--searchbar),
input[type=email],
input[type=password],
input[type=time],
select,
textarea{
  box-shadow: none;
  border-radius: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  min-height: 45px;
}

.input{
  min-height: 45px;
}

.aimeos input,
input {
    &:disabled {
        background: #f5f5f5fb;
        border: 1px solid $light-gray;
        cursor: not-allowed;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;

        @include pseudos {
            border-color: $light-gray;
        }
    }
}

input[type=checkbox] {
    opacity: 0;
    height: 0;
    width: 0;

    &:checked {
        & ~ span.checkbox {
            background-color: $primary-color;

            &:after {
                content: "\2713";
                color: $white;
                font-size: 11px;
                position: absolute;
                left: 0;
                right: 0;
                top: 50%;
                bottom: 0;
                text-align: center;
                transform: translateY(-50%);
                height: 12px;
            }
        }
    }
}

span.checkbox {
    position: absolute;
    border: 1px solid darken($primary-color, 10%);
    width: 15px;
    height: 15px;
    display: inline-block;
    right: 15px;
    top: 50%;
    margin-top: -7.5px;
}
