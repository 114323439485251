.main-product-marker {
    width: 30px;
    height: 30px;
    border: 1px solid $black;
    border-radius: 50%;
    display: inline-block;

    &:before {
        content: 'A';
        text-align: center;
        width: 30px;
        height: 30px;
        display: block;
        font-size: 22px;
        line-height: 32px;
    }
}
