.tile {
    background-color: $primary-color;
    color: $white;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .tiles-button-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        @media (min-width: 0px) and (max-width: 991px) {
            flex-direction: column;
        }
    }
    .tiles-button {
        border-radius: 10px;
        background-color: $primary-color;
        border: 1px solid $white;
        padding: 5px 40px;
        height: 30px;
        margin-bottom: 5px;
        display: inline-block;
    }

    .img-holder {
        text-align: right;

        @media (min-width: 0px) and (max-width: 991px) {
            text-align: center;
            padding: 1rem;
        }
    }

    svg{
        stroke: $white;
        fill:none;
        stroke-linecap:round;
        stroke-linejoin:round;
    }
}

.two-tiles {
    padding-top: 15px;
    width: 100%;
}

.content-tile{
    margin-top: 15px;
    .content-tile-container{
        background-color: $white;
        padding: 10px;
        border-radius: 20px;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .content-tile-image-container{
            display: flex;
            justify-content: center;
            align-items: center;
            img{
                width:190px;
            }
        }
        .content-tile-text-container{
            display: flex;
            justify-content: center;
            width: 70%;
            margin: 0 auto;
        }
    }
}
