.accordion {
    border: 1px solid $gray-background;
    margin: 10px 0;

    .accordion-header {
        background-color: $gray-background;
        display: flex;
        justify-content: space-between;
        padding: 5px 20px;
        font-size: 18px;
        font-weight: $font-bold;
        width: calc(100% + 1px);
        align-items: center;

        &:hover {
            cursor: pointer;
        }
    }
    .accordion-body {
        display: none;
        padding: 20px 10px;

        &.open {
            display: block;
        }
    }
}
