.aimeos.catalog-list {
    .product {
        .price-list {
            padding: 5px 0;

            &:hover {
                .price-text {
                    margin-top: -27px;
                }
            }

            .price-actual {
                .price-text {
                    padding-top: 1px;

                    .value {
                        vertical-align: text-top;
                        font-weight: normal;
                    }

                    .currency {
                        vertical-align: text-top;
                    }
                }
            }
        }

        .text-list {
            min-height: 150px;
            align-items: initial;

            h2 {
                font-size: 19px;
                text-transform: inherit;
                font-weight: $font-bold;
                height: auto;
                color: $dark-gray;
                line-height: 23px;
                margin-bottom: 0;
            }
        }

        .offer {
            border-radius: 15px;
            overflow: hidden;
        }
    }
}

// Overwrite placeholder
.catalog-list a .media-list,
.aimeos .suggest-item .item-image,
.basket-related-bought .media-list,
.catalog-detail-bought .media-list,
.catalog-detail-bundle .media-list,
.catalog-detail-suggest .media-list,
.catalog-session-seen .media-list,
.account-watch .media-item,
.account-favorite .media-item,
.basket-related-bought .media-item,
.catalog-detail-bought .media-item,
.catalog-detail-bundle .media-item,
.catalog-detail-suggest .media-item,
.catalog-session-seen .media-item,
.catalog-session-pinned .media-item,
.catalog-detail-image .image-single {
    background-image: url("../../../../images/placeholder.png");
}

.aimeos-container .catalog-detail-suggest {
    display: none;
}

.aimeos.catalog-list.buy-box .product .price-list {
    background-color: darken($primary-color , 10%);
    border: 1px solid darken($primary-color , 10%);

}

.aimeos {
    .buy-box {
        .btn-primary {
            padding: 0;
            width: 10em;
            background-color: darken($primary-color , 10%);
            border: 1px solid darken($primary-color , 10%);
        }

    }
}
.buy-box {
    .product {
        margin: 0;

    .media-item {
            background: #fff;
        }
    }
    }
.ui-menu .ui-state-active,
.ui-menu .ui-menu-item {
    &:hover {
        background-color: #c0c0c0 !important;
    }
&:focus {
    background-color: #c0c0c0 !important;
}
    background-color: #fff !important;
}
.service-choose {
    .btn-primary {
        color: #fff;
    }
}

.scooter_notice {

    box-sizing: border-box;

    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px;
    gap: 10px;
    padding: 1rem;

    background: #FFEDED;
    border: 1px solid #F00000;
    border-radius: 10px;

    margin: 0 0 2rem 0;

    flex: none;
    order: 3;
    flex-grow: 0;

    span {
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 18px;
        color: #8A0000;
    }

    svg {
        width: 32px;
    }

    .dealer-search-link {
        text-decoration: underline !important;
        color: #8A0000 !important;
    }
}


.detail-back {
    display: grid;
    justify-items: start;

    svg {
        stroke: $primary-color;
        margin-right: 1rem;
    }

    a {
        display: flex;
        align-items: center;
        font-weight: 700;
        font-size: 1.125rem;
        text-transform: uppercase;
    }
}
