.portal--safety-first {
    .icon {
        width:100%;
    }
    background-color: $white;

    .mechaniker {
        height: 100%;
        width: 70%;
    }

    .safety-box {
        @include mobile {
            margin: 20px 0;
        }

        .safety-box-inner {
            border: 1px solid $black;
            border-radius: 50px;
            margin-bottom: 20px;
            padding: 20px;
            max-height: 280px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            font-size: 30px;
            font-family: 'Neuzeit Regular', sans-serif;
            height: 100%;
        }
    }

    .portal--safety-first-headline {
        font-size: 32px;
        padding-bottom: 25px;
        font-family: 'Bradford Medium', sans-serif;
        margin-top: 40px;

        @include tablet {
            margin-bottom: 50px;
        }
    }

    .portal--safety-first-headline-second {
        font-size: 23px;
        font-family: 'Neuzeit Regular', sans-serif;

        @include tablet {
            margin-bottom: 80px;
        }
    }

    .portal--headline {
        text-align: center !important;
        width: 100%;

        h6 {
            font-size: 18px;
            font-family: "Neuzeit Bold", sans-serif;

            @include desktop {
                font-size: 25px;
            }
        }

        p {
            font-size: 14px;
            text-align: center !important;
            @include desktop {
                font-size: 16px;
            }
        }
    }

    .portal--body {
        text-align: center;

        .icon {
            max-width: 120px;
            width: 120px;
            margin-bottom: 10px;
        }
    }

    .portal--safety-first-hint {
        margin: 30px 80px 0 80px;

        .portal--safety-first-hintbox {
            background: #eee;
            border: 1px solid $black;
            padding: 5px;
            font-size: 25px;
            border-radius: 20px;
            text-align: center;
        }
    }
}
section.service-packages-processing-container {
    padding: 2rem 0;
    ;

    .icon {
        width:100%;
    }
}

.service-packages-processing {

    display: grid;
    gap: 20px;

    @include mobile {
        display: flex;
        flex-direction: column;
    }

    >div {
        border: 1px solid #000;
        border-radius: 50px;
        margin-bottom: 20px;
        padding: 20px;
        max-height: 280px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        font-size: 30px;
        font-family: "Neuzeit Regular", sans-serif;
        height: 100%;
    }
}

.max-icon {
    max-width: 310px;
}

.number-circle {
    border-radius: 50%;
    text-align: center;
    height: 45px;
    background-color: #000;
    width: 45px;
    margin: 0px;

    .number {
        text-align: center;
        color: #fff;
        display: inline-block;
        font-size: 30px;
        font-family: "Bradford Medium", sans-serif;
    }
}
