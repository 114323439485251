h1,
h2,
h3 {
    font-family: "Bradford Medium", sans-serif;
}

a,
p,
span {
    font-family: "Neuzeit Regular", sans-serif;
}

h1,
.aimeos h1 {
    font-weight: inherit;
    text-transform: inherit;
}

h2,
.aimeos h2 {
    font-weight: inherit;
}

h3,
.aimeos h3 {
    text-transform: inherit;
    font-weight: inherit;
}
