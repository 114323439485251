.icon {
    min-width: 20px;
    min-height: 20px;
}

.arrow-right-white--icon {
    background-image: url('../images/icons/arrow-right-white.svg');
    @include icon-sizes;
}

.arrow-right-black--icon {
    background-image: url('../images/icons/arrow-right-black.svg');
    @include icon-sizes;
}

.arrow-left-white--icon {
    background-image: url('../images/icons/arrow-left-white.svg');
    @include icon-sizes;
}
.arrow-left-black--icon {
    background-image: url('../images/icons/arrow-left-black.svg');
    @include icon-sizes;
}

.arrow-down--icon {
    background-image: url('../images/icons/arrow-down.svg');
    @include icon-sizes;
}

.arrow-down--icon-white {
    background-image: url('../images/icons/arrow-down-white.svg');
    @include icon-sizes;
}

.arrow-down--icon-orange {
    background-image: url('../images/icons/arrow-down-orange.svg');
    @include icon-sizes;
}

.arrow-up--icon {
    background-image: url('../images/icons/arrow-up.svg');
    @include icon-sizes;
}

.arrow-up--icon-white {
    background-image: url('../images/icons/arrow-up-white.svg');
    @include icon-sizes;
}

.arrow-up--icon-orange {
    background-image: url('../images/icons/arrow-up-orange.svg');
    @include icon-sizes;
}

.plus--icon {
    background-image: url('../images/icons/plus.svg');
    @include icon-sizes;
}

.plus-blue--icon {
    background-image: url('../images/icons/plus-blue.svg');
    @include icon-sizes;
}

.minus--icon {
    background-image: url('../images/icons/minus.svg');
    @include icon-sizes;
}

.minus-blue--icon {
    background-image: url('../images/icons/minus-blue.svg');
    @include icon-sizes;
}

.edit--icon {
    background-image: url('../images/icons/edit.svg');
    @include icon-sizes;
}

.housebreaking--icon-secondary {
    background-image: url('../images/icons/housebreaking-secondary.svg');
    @include icon-sizes;
}

.damage--icon-secondary {
    background-image: url('../images/icons/damage-secondary.svg');
    @include icon-sizes;
}

.delete--icon {
    background-image: url('../images/icons/delete.svg');
    @include icon-sizes;
}

.haken--icon {
    background-image: url('../images/icons/haken.svg');
    @include icon-sizes;
}

.anhang {
    width: 30px;
    height: 30px;
    margin-right: 5px;
}

.present {
    min-width: 28px;
    min-height: 28px;
}
.w-full {
    width: 100%;
}
