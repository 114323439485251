.shop-intro{
    .icon {
        width:100%;
    }
}

.intro--advantages-overlay {
    .icon {
        width:100%;
    }
    background-color: $primary-color;
    padding: 55px 40px;

    .intro--advantages {
        background-color: $primary-color;

        .intro--headline {
            color: $white;
            font-size: 23px;
            font-family: "Bradford Medium", sans-serif;
            padding-bottom: 30px;

            @include tablet {
                padding-bottom: 50px;
                font-size: 39px;
            }
        }

        .intro--advantages-boxes {
            justify-content: center;

            .intro--advantages-box {
                margin-top: 15px;
            }

            .icon {
                width: 100%;
                stroke: $white;
                max-height: 130px;
                margin: 0 auto;
                display: block;
                stroke-width: 0.8;
            }

            .intro--advantage-text {
                display: block;
                font-family: "Neuzeit Bold", sans-serif;
                text-align: center;
                font-size: 20px;
                padding-top: 30px;
                color: $white;

                @include mobile {
                    max-width: unset;
                    width: auto;
                }
            }
        }
    }
}

#intro--banner {

    overflow: hidden;

    @include mobile {
        max-height: 520px;
        height: 520px;
    }

    .eleasaauffahrrad {
        width: 100%;
        height: 100%;
    }

    .intro-text {
        display: block;
        font-family: "Bradford Medium", sans-serif;
        font-size: 39px;
        padding-bottom: 40px;

        @include mobile {
            margin-top: 20px;
            font-size: 30px;
        }
    }

    .intro-image {
        display: block;
        width:100%;
        @include mobile {
            display: none;
        }
    }

    .intro-grid{
        display: flex;
        flex: 0 0 50%;
        max-width: 50%;
        @include mobile {
            display: block;
            width: 100%;
            max-width: 100%;
            flex: 0 0 100%;
        }
    }

    .intro-anchor-links{
        font-size: 14px;
        @include mobile {
            font-size: 25px !important;
        }
    }

    .intro--textbox {
        padding: 40px 20px;

        @include mobile {
            padding: 20px;
        }

        .anchor-block {
            display: flex;
            flex-direction: column;
            justify-content: center;

            @include tablet {
                align-items: flex-start;
            }

            .btn-anchor {
                padding: 10px 20px;
                background-color: $white;
                border-radius: 20px;
                margin-bottom: 20px;
                line-height: 1;
                transition: background-color .2s ease;
                height: 70px;
                /* IE11 Changes */

                @include tablet {
                    min-width: 240px;
                    height: 40px;
                }

                &:hover {
                    cursor: pointer;
                    background-color: $primary-color;

                    a {
                        color: $white;

                        .pfeil-lang {
                            stroke: $white;

                            .st0 {
                                stroke: $white;
                            }
                        }
                    }
                }

                a {
                    color: $primary-color;
                    transition: color .2s ease;
                    font-size: 14px;
                    font-family: "Bradford Medium", sans-serif;

                    @include tablet {
                        font-size: 20px;
                    }

                    &:hover {
                        text-decoration: none;
                    }

                    .pfeil-lang {
                        width: 14px;
                        stroke: $primary-color;
                        padding: 0;
                        margin-right: 10px;

                        @include tablet {
                            width: 25px;
                            margin-right: 20px;
                        }

                        .st0 {
                            stroke: $primary-color;
                        }
                    }
                }
            }
        }
    }
}

.col-span-2 {
    grid-column: span 2 / span 2;
}

.col-span-3 {
    grid-column: span 3 / span 3;
}

.col-span-4 {
    grid-column: span 4 / span 4;
}

.col-span-5 {
    grid-column: span 5 / span 5;
}

.grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
}


.grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
}

.grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
}

.grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
}


.grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
}



.grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
}

.bg-white {
    background: $white;
}

.text-xs {
    font-size: 14px;
}

.text-sm {
    font-size: 16px;
}

.text-base {
    font-size: 24px;
}

.text-lg {
    font-size: 26px;
}

.section-calculator{
    background: #edf2f7;
    padding: 10px 0;
}

.calculation {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));

    >div {
        display: flex;
        align-content: center;
        flex-wrap: wrap;
    }

    .headline {
        padding-bottom: 2rem;
    }

    padding-bottom: 0.5rem;
}

.headline {
    font-family: "Bradford Medium", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 37px;
    line-height: 120%;
    letter-spacing: 0.03em;
    display: block;
}

.box-wrapper {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 30px;
    padding: 0 0 2rem;

    @include mobile {
        display: flex;
        flex-direction: column;
    }
}

.box {
    background-color: $primary-color;
    padding: 20px;
    border-radius: 20px;
    color: $white;
    font-size: 19px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .box-content {
        display: grid;
        gap: 10px;
        padding-bottom: 2rem;
        font-weight: 400;
        font-size: 19px;
        line-height: 140%;

        .headline {
            padding-bottom: 12px;
            font-style: normal;
            font-weight: 500;
            font-size: 22px;
            line-height: 29px;
        }
    }

    a {
        border: 1px solid $white;
        border-radius: 10px;
        display: inline-block;
        color: $primary-color;
        background-color: $white;
        padding: 2px 10px;
        align-self: flex-end;
        font-style: normal;
        font-weight: 700;
        font-size: 19px;
    }
}

.intro--how-to-container {
    padding: 4rem 0;
}

.intro--how-to-body {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 2rem;
    justify-content: center;
    padding: 2rem 0; 

    @include mobile {
        display: flex;
        flex-direction: column;
    }

    >div {
        padding: 20px;
        border-radius: 40px;
        border: 1px solid $black;
        font-size: 19px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        .svg {
            display: grid;
            align-items: center;
            width: 100%;
            justify-content: center;
            padding-bottom: 10px;

            svg {
                height: 160px;

            }
        }

        a {

            background: $primary-color;
            border-radius: 10px;
            display: inline-block;
            color: $white;
            padding: 0 15px;
        }
    }

}

.dealer-search--info-box {
    margin-top: 65px;
    font-family: "Neuzeit Regular", sans-serif;
    font-size: 20px;
    margin-bottom: 80px;
    display: flex;
    align-items: center;

    a {
        color: $black;
        text-decoration: underline;
    }

    @include tablet {
        font-size: 23px;
    }

    span {
        display: inline-block;
        padding-top: 10px;
    }

    .portal-info {
        margin-right: 17px;
        display: inline-block;

        @include tablet {
            width: 41px;
            height: 41px;
            min-width: 41px;
        }
    }

    &.desired-bike {
        background-color: $primary-color;
        border-radius: 30px;
        padding: 0.6rem 1rem;
        font-size: 22px;
        color: $white;
        margin: 5rem 0;

        a {
            color: $white;
            white-space: nowrap;
        }

        .portal-info {
            width: 62px;
            height: 60px;
            min-width: 60px;
        }
    }
}
