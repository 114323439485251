.calculator {

    .highligh-border {
            border: 1px solid $primary-color;
            background-color: $light-gray;
        }
    .row {
        margin-top: 1rem;
        margin-bottom: 1rem;

        &.mobile-info-container {
            margin: 0;
        }

        .image {
            display: flex;
            align-items: center;

            img {
                max-width: 100%;
            }
        }

        .col-sm-4 {
            text-align: right;

            &:first-child {
                text-align: left;
            }
        }
    }

    label {
        font-weight: $font-bold;
        max-width: 85%;

        &.checkbox-label {
            width: 305px;
            position: relative;

            @include mobile {
                .checkbox {
                    right: -15%;
                }
            }
        }
    }

    select,
    input,
    fieldset label {
        margin-top: 0;
    }

    fieldset {
        label {
            font-weight: $font-regular;
            display: inline-flex;
            align-items: center;
            margin-right: 50px;
        }

        input + label {
            margin-right: 50px;
            margin-left: 5px;
        }
    }

    .form-field,
    .select-value {
        margin-bottom: 25px;
    }

    .striped {
        padding: 10px 0;
        background: $light-gray;
        font-size: 20px;
    }
    .state-container {
        display: block;
    }
    .sized {
        font-size: 20px;

        @include mobile {
            font-size: 18px;
        }
    }

    .bold {
        font-weight: $font-bold;
    }

    .calculate {
        cursor: pointer;
        padding: 10px 25px;
        font-size: 21px;
        line-height: 27px;
        font-weight: 200;
        display: block;
        width: 50%;
        max-width: 400px;
        margin: 30px 0 40px 0;
        text-align: center;

        @include mobile {
            width: 100%;
        }
    }

    .processing {
        .checkbox {
            right: 0;
        }
    }

    .info {
        float: right;

        .icon {
            display: inline-block;
            float: right;
            width: 30px;
            height: 30px;
        }

        @include mobile {
            position: relative;
        }
    }

}
