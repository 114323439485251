.catalog-stage-subnavigation{
    margin-bottom: 30px;
    .catalog-subnavigation {
        overflow: hidden;
        border: 0 none;
        a{
            border: 1px solid $gray;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $white;
            border-radius: 20px;
        }
        @include rounded;

        &.selected {
            background-color: $primary-color;

            span {
                color: $white;
            }
        }

        .sub-navigation-content {
            padding: 20px;

            .sub-navigation-image-container {
                width: 100%;
                display: flex;
                justify-content: center;
                padding-bottom: 5px;

                img {
                    width: 150px;
                }
            }

            .sub-navigation-text-container {
                width: 100%;
                display: flex;
                justify-content: center;
                padding-top: 10px;

                span {
                    text-align: center;
                }
            }
        }
    }
}
