.footer {
    background-color: $primary-color;

    .fb-link-text {
        color: $white;
    }

    .footer-info {
        color: $white;
    }

    .footer-menu--item {
        padding-bottom: 0;

        a, .footer-info {
            color: $white;
        }
    }

    h2 {
        color: $white;
        text-transform: none;
        margin-bottom: 10px;
    }

    .cooperation-banner {
        color: $white;

        span {
            display: block;
            font-size: 25px;
        }

        .eleasa {
            max-width: 250px;
        }
    }
}
