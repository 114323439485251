h1,
h2,
h3 {
    font-family: $font-family-sans-serif;
}

h1,
.aimeos h1 {
    font-weight: $font-light;
    line-height: 38px;
    font-size: 35px;
    margin-bottom: 30px;

    @include mobile {
        font-size: 30px;
        line-height: 35px;
    }
}

h2,
.aimeos h2 {
    font-size: 30px;
    line-height: 35px;
    font-weight: $font-light;
    text-transform: none;

    @include mobile {
        font-size: 25px;
        line-height: 30px;
    }
}

h3,
.aimeos h3 {
    font-size: 23px;
    line-height: 23px;
    text-transform: uppercase;
    font-weight: $font-regular;
}

a,
a:hover,
a:link,
a:visited,
a:active,
a:focus {
    text-decoration: none !important;
    outline: 0 none !important;
}

a,
.aimeos a,
.aimeos a:link:not(.btn),
.aimeos a:visited:not(.btn) {
    color: $dark-gray;
    text-decoration: none;
}

p {
    text-align: initial !important;
}


a[href^="tel"] {
    color: inherit;
    text-decoration: none;
}


