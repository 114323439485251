.aimeos.account-profile {
    .profile-header{
         margin-bottom: 30px;
    }

    .profile-into {
        margin-bottom: 60px;
    }

    .profile-edit-data {
        margin-bottom: 56px;
    }

    .account--data__item {

        .form-group {
            .minibutton {
                span {
                    font-size: 17px;
                    display: flex;
                    align-items: center;
                }
            }
        }

        .info-box--header {
            h3 {
                margin-top: 0;
            }
        }
        label {
            font-weight: $font-bold;
            margin: 0;
        }

        button {
            width: 16em;
        }

        .note {
            margin: 60px 0;
        }
    }

    .profile-value {
        margin-bottom: 4px;
        display: block;
    }

    .account-profile-personal-data {

        .error-list {
            list-style: none;
        }

        .personal-data {
            margin: 30px 0;

            .name,
            .value {
                display: inline-block;
            }

            .name {
                width: 210px;
            }
        }

        form {
            label {
                margin-top: 25px;
                margin-bottom: 0;
                font-weight: $font-bold;
                width: 100%;
                height: 25px;
            }

            .form-item {
                &.error {

                    label {
                        color: #FF3300;
                    }

                    input {
                        background-color: #ffeeee;
                        border: solid 1px #FF3300;
                    }
                }

                input {
                    margin-top: 5px;
                }
            }

            .btn-primary {
                width: 30%;
                padding: 10px 25px;
                margin-top: 15px;

                @include mobile {
                    width: 100%;
                }
            }
        }

        .note {
            margin-top: 25px;
        }

    }

    .account--data__item{
        @include mobile{
            margin-bottom: 30px;
        }
    }

    .customer-info-item {
        display: block;
        margin-bottom: 5px;
    }

    .order-history-tile{
        margin-bottom: 30px;

        .account-data-content {
            padding-bottom: 20px;
        }
    }

    .header-and-icon {
        display: flex;
        align-items: center;
        margin-bottom: 30px;

        .icon {
            &.package {
                width: 60px;
                height: 60px;
            }
        }

        h3 {
            margin: 0;
            font-size: 34px;
        }
    }

    .contact-headline {
        margin-top: 60px;
    }
}

.item-address-form {
    h3{
        margin-top: 80px;
        font-weight: $font-regular;
        text-transform: uppercase;
    }
}

.delivery-address-headline-text {
    margin-bottom: 60px;
}

.item-updateable {
    button {
        background: transparent;
        border: 0 none;
    }
}

.btn-update{
    padding: 2px 40px;
    &:hover{
        cursor: pointer;
    }
}
