body {
    line-height: 1.45;
    font-size: 16px;
    font-family: $font-family-sans-serif;
    font-weight: $font-regular;
    color: $dark-gray;
    background-color: $light-gray;
}

.body {
    padding: 30px 0 50px 0;
    @include mobile {
        padding-top: 10px;
    }
}

svg {
    &.white {
        fill: $white;
    }

    &.black {
        fill: $black;
    }

    &.anthracite {
        fill: $dark-gray;
    }

    &.primary {
        fill: $primary-color;
    }

    &.secondary {
        fill: $secondary-color;
    }
}

svg.calculator {
    &.white {
        stroke: $white;
    }

    &.black {
        stroke: $black;
    }

    &.anthracite {
        stroke: $dark-gray;
    }

    &.primary {
        stroke: $primary-color;
    }

    &.secondary {
        stroke: $secondary-color;
    }
}

input:not(.portal--searchbar) {
    outline: 0 none !important;
}

input[type=radio] {
    -webkit-appearance: none;
    border: 1px solid darken($primary-color, 10%);
    border-radius: 50%;
    width: 15px;
    height: 15px;
    position: relative;
    margin-right: 5px;
    background: $white;

    @media all and (-ms-high-contrast: none) {
        *::-ms-backdrop, & {
            -webkit-appearance: none;
            border: 1px solid darken($primary-color, 10%);
            border-radius: 50%;
            width: 20px;
            height: 20px;
            position: relative;
            margin-right: 5px;
        }
        /* IE11 */
        &:checked {
            background: $primary-color;
        }
    }

    &:after {
        content: '';
        width: 5px;
        height: 5px;
        background: $white;
        position: absolute;
        top: 50%;
        border-radius: 50%;
        left: 50%;
        margin-left: -2.5px;
        margin-top: -2.5px;
    }

    &:checked {
        background: $primary-color;
    }
}

.btn {
    font-size: 20px;
    font-weight: $font-bold;
}

.btn-primary {
    vertical-align: top;
    border-radius: 0;
    width: 12em;
    background: $primary-color;
    border: 1px solid $primary-color;
}

.btn-primary--bordered {
    vertical-align: top;
    border-radius: 0;
    width: 12em;
    background: $white;
    border: 2px solid $primary-color;
}

select.form-control,
input.form-control,
.form-control.input {
    height: auto;
    -webkit-appearance: none;
    border: 1px solid $light-gray;
    padding: 10px 15px;
    margin: 10px 0;
    border-radius: 0;
    box-shadow: none;

    &.disabled {
        background: #f5f5f5fb;
        cursor: not-allowed;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;

        @include pseudos {
            border-color: $light-gray;
        }
    }

    @include pseudos {
        border-color: #8D8D8D;
        box-shadow: none;
    }
}

.select-value {
    position: relative;

    &:after {
        content: '';
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        pointer-events: none;
        z-index: 0;
        @extend .arrow-down--icon;
    }

    input {
        z-index: 1;
        position: relative;
    }
}

.invalid-feedback,
.alert-success,
.aimeos .error-list {
    padding: 15px;
    margin-bottom: 15px;
    display: block;
    font-weight: $font-light;
    font-size: 1rem;
    border: none;
    border-radius: 0;
    color: $white;
    list-style: none;
}

.invalid-feedback,
.aimeos .error-list {
    background-color: $error-color;
}

.aimeos .error-item {
    margin-left: 20px;
    padding: 0;
}

.alert-success {
    background-color: $success-color;

    .success-item {
        margin-left: 20px;
    }
}

.container-short {
    width: 90%;
    min-height: 18em;
    margin: 0 auto;
}


.account-subscription .header,
.account-history .header,
.account-watch .header,
.account-favorite .header,
.basket-related-bought .header,
.catalog-session .header,
.catalog-detail .header {
    border-bottom: 1px solid $light-gray;
    font-size: 1rem;
}

.aimeos-container {
    max-width: 75em;
}

#header-and-body {
    background-color: $white;
}

.kontakt_text {
    font-weight: $font-bold;
}

select::-ms-expand {
    display: none;
}

input::-ms-clear {
    display: none;
}

.form-item,
.service-form-item {
    &.error {

        label {
            color: #FF3300 !important;
        }

        input,
        textarea,
        select {
            background-color: #ffeeee !important;
            border: solid 1px #FF3300 !important;
        }
    }
}

.error-text{
    color: #FF3300 !important;
}
.error-bg{
    background-color: #ffeeee !important;
}
.error-border{
    border: solid 1px #FF3300 !important;
}
img {
    @include pseudos {
        outline: 0 none !important;
    }
}

.form-headline {
    margin-bottom: 35px;
}
