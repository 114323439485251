.page-breadcrumb {
    padding-bottom: 24px;
    padding-left: 10px;
    border-bottom: 1px dashed $gray-background;
    width: 100%;
    margin-bottom: 50px;
    font-size: 17px;
    color: $dark-gray-font;

    span {
        display: inline-block;

        &.breadcrumb-name {
            margin-left: 18px;
        }
    }
}
