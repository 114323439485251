.calculator {

    &.calculator-page {
        padding: 25px;
        background: $white;
    }

    .buttons {
        margin-top: 30px;

        @include desktop {
            margin-top: 0;
        }
    }

    .calculate {
        font-size: 18px;
        line-height: 19px;
        min-height: 60px;
        padding: 10px 80px 5px 80px;
        width: 100%;
        margin: 0;
        margin-bottom: 15px;
        font-family: "Neuzeit Regular", sans-serif;
        display: flex;
        align-items: center;
        justify-content: center;

        @include desktop {
            width: auto;
        }
    }

    .image {
        svg {
            width: 50%;
            margin: 0 auto;

            @include desktop {
                width: 100%;
            }
        }
    }
}

.calculator-tiles {
    margin-bottom: 10px;

    .tile {
        background: $white;
        padding: 20px;
        border: none;
        height: auto;

        .tiles-header {
            h3 {
                color: $black;
                font-family: $font-family-base;
            }
        }
        .tiles-text {
            display: none;
            min-height: auto;

            p {
                color: $black;
            }
        }
        .tiles-button {
            height: auto;
            padding: 5px 45px;
            line-height: 20px;
            font-size: 16px;
            background: $white;
            border: 1px solid $primary-color;
            color: $primary-color;

            .less {
                display: none;
            }
        }
    }
}
