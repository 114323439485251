.calculator-saving-layer {
    background-color: #ececed;
    padding: 20px 10px 10px 10px;
    font-size: 14px;
    margin-bottom: 25px;

    .saving-box {
        padding: 5px 0;
    }

    .button-layer {
        margin-bottom: 0;
        margin-top: 10px;
    }

    @include desktop {
        padding: 8px;
        font-size: 16px;
        margin-bottom: 0;

        .button-layer {
            margin-bottom: 0;
            margin-top: 0;
        }
    }
}

#calculator_details {
    .info-small {
        max-width: 30px;
        max-height: 30px;
    }
}
