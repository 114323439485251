.ui-widget-content {
    z-index: 9999;
    width: 393px !important;

    @include mobile {
        display: none !important;
    }
}

.aimeos.catalog-suggest {
    .suggest-item {
        justify-content: center;
        display: flex;
        align-items: center;

        .item-name {
            width: 75%;
            font-size: 13px;
            padding: 0 5px;
        }

        .item-image {
            width: 25%;
            margin: 5px 0;
        }
    }

}
