.catalog-detail-similar,
.last-seen {
  .product {
    .price-list {
      padding: 15px 0;
      background-color: $primary-color;
      width: 100%;
      color: $white;
      font-size: 18px;
      font-weight: $font-light;

      &:hover {
        .price-text {
          margin-top: -26px;
        }
      }

      height: 26px;

      .price-text {
        transition: all .3s ease;
        padding-top: 2px;
      }
    }
    .mpp-price-item{
      height: 26px;
      overflow: hidden;
    }

    h3.name{
      font-size: 19px;
      text-transform: inherit;
      font-weight: 700;
      color: #3b3b3b;
      line-height: 23px;
      margin-bottom: 0;
      height: 80px;
    }
  }
}