.checkout-standard {

    .button-group {
        display: block;
        overflow: hidden;

        .btn-default {
            float: left;
            margin-left: 0;

            @include mobile {
                float: none;
                width: 100%;
                margin: 0;
                margin-bottom: 15px;
            }
        }

        .btn-primary {
            float: right;

            @include mobile {
                width: 100%;
                float: none;
                margin: 0;
            }
        }
    }

    .steps {
        margin: 20px 0 0;
        font-weight: $font-regular;

        .step {
            border-color: $light-gray;

            &:before {
                content: counter(step) ". ";
                counter-increment: step;
            }

            &.active {
                border-color: $gray;

                &:after {
                    content: none;
                }
            }

            &.current {
                border-color: $dark-gray;
            }

            @include mobile {
                width: 32%;
            }
        }
    }

    .form-list {

        .form-item {
            label {
                margin-bottom: 0;
                margin-top: 15px;
            }

            .form-control {
                margin-top: 5px;
            }
        }

        .optional,
        .mandatory {
            font-weight: $font-bold;
        }

        .success {
            input,
            select {
                background-color: transparent;
            }
        }

    }

    .checkout-standard-address {

        label {
            font-weight: $font-bold;

            span {
                font-weight: $font-regular;
            }
        }

        h2 {
            padding: 0 15px;
        }
        .flex{
            display: flex;
            flex-direction: row;
            margin-bottom: 30px;
        }
        .personal-info {
            margin-bottom: 60px;

            &-row {
                padding-bottom: 5px;
            }
        }

        .form-list {
            .mandatory {
                display: block;
            }
        }

        .item-address {
            flex: auto;
            padding: 0 15px;
            height: 100%;
            border: 1px solid $gray-border;

            &.item-new{
                .header {
                    min-height: 0;
                    border: 0 none;
                }
            }

            .header{
                background: none;
                height: 100%;

                .btn-update{
                    padding: 2px 40px;
                    &:hover{
                        cursor: pointer;
                    }
                }

                input[type="radio"]{
                    border: 1px solid darken($primary-color, 10%);
                    margin-left: 22px;
                }

                #ca_mpp_deliveryoption-null{
                    margin-left: 7px;
                }

                input:not([type="radio"]) {
                    border: 1px solid $controls-gray;
                    margin: 5px 0 10px 0;

                    &:hover{
                        border-color: $controls-gray-hover;
                    }
                }

                label {
                    &.values {
                        margin-left: 23px;
                    }
                    span{
                        margin-bottom: 4px;
                    }
                }
            }
            .values{
                display: inline-flex;
                flex-direction: column;
            }
            .form-item.company {
                display: none !important;
            }
        }

        .note {
            margin-top: 25px;
        }

        .checkout-standard-address-delivery {
            display: block;
        }
        .checkout-standard-address-billing {
            width: 100%;

            .header {
                display: none;
            }
        }

        .contact-info {
            margin-top: 82px;
            .contact-info-header {
                margin-bottom: 13px;
                text-transform: uppercase;
            }
        }
    }

    .checkout-standard-delivery {
        display: none;
    }

    .checkout-standard-payment {
        & *:not(.button-group) {
            display: none;
        }
    }

    .checkout-standard-summary {
        .common-summary-address {
            .item {
                &.payment {
                    margin-bottom: 30px;

                    .payment-body {
                        background: $light-gray;
                        height: 100%;

                        .header {
                            border-bottom: 1px solid $gray;

                            h3 {
                                font-weight: $font-regular;
                            }
                            .modify {
                                background-color: transparent;
                                color: $black;
                                padding: 3px 20px;
                            }
                        }

                        .header,
                        .content {
                            padding: 20px 30px;
                            margin: 0;
                        }
                    }
                }
            }
        }

        .common-summary-detail {
            thead {
                background-color: $light-gray;

                tr {
                    border-bottom: 1px solid $gray;
                }
            }
            tbody {
                tr {
                    border-bottom: 1px solid $gray;
                }
            }
            tr.total {
                background-color: $light-gray;
                border-top: 1px solid $gray;
            }

            .header,
            .basket {
                margin: 0;
            }
        }
    }

    .button-layer{
        a:first-child{
            pointer-events: none;
            div{
                display: none;
            }
        }
    }

    .checkout-standard-summary-option {

        label {
            position: relative;

            span.checkbox {
                left: 0;
            }
        }
    }
}

.value-new{
    margin-left: 10px;
}

.mpp_deliveryoption{
    transition: max-height 0.3s ease-out;
    max-height: 0;
    overflow: hidden;

    &.open {
        max-height: 1200px;
        margin-top: 40px;
    }

    .btn-safe-new-address {
        margin: 30px 0;
    }
}

.common-summary-detail{
    .header{
        a.modify {
            background-color: transparent;
            color: $black;
            padding: 3px 20px;
        }
    }
}

.form-control {
    &.is-invalid {
        border-color: #dc3545 !important;
        padding-right: 2.25rem;
        background-repeat: no-repeat;
        background-position: center right calc(2.25rem / 4);
        background-size: calc(2.25rem / 2) calc(2.25rem / 2);
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23d9534f' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
    }
}
