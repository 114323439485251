.basket-standard {

    & > h1 {
        margin-bottom: 20px;
        font-size: 30px;
        font-weight: $font-regular;
        text-transform: capitalize;

    }

    .common-summary-detail {
        .basket {
            margin: 30px 0 0 0;
        }
    }

    .basket-standard-coupon {
        .coupon-new {
            input {
                margin: auto;
                border: 1px solid darken($primary-color, 10%);
            }
        }
    }

    .button-group {
        display: flex;
        justify-content: space-between;

        .btn-default {
            @include mobile {
                width: 100%;
            }
        }

        .btn-primary {
            color: $white;

            @include pseudos {
                color: $white;
            }

            @include mobile {
                width: 100%;
            }
        }
    }
    .button-layer{
        a:nth-child(2){
            pointer-events: none;
            div{
                display: none;
            }
        }
    }

    .steps {
        margin: 20px 0 0;
        font-weight: $font-regular;
        text-align: center;
        counter-reset: step;
        padding: 0 0 3em 0;

        .step {
            border-color: $light-gray;
            display: inline-block;
            overflow: hidden;
            width: 19%;
            color: $gray-step-color;
            border-bottom: solid 8px $gray-step-color;
            border-bottom-color: rgb(211, 219, 222);
            vertical-align: baseline;

            &.active {
                border-color: $gray;

                &:after {
                    content: none;
                }
            }

            &.current {
                border-color: $dark-gray;
            }

            @include mobile {
                width: 32%;
            }
        }
    }
}

.basket {

    .info-small {
        display: inline-block;
        width: 30px;
        height: 30px;
    }

    .table {
        .table-head {
            background: $light-gray;
            padding: 10px 0;
            font-weight: $font-bold;

            .th {
                text-align: center;

                &.unitprice {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                &.quantity {
                    @include mobile {
                        display: none;
                    }
                }

                @include mobile {
                    text-align: left;
                }

                .info {
                    height: 30px;
                    width: 30px;

                    .info--text {
                        top: 30px;
                    }
                }
            }
        }

        .table-body {
            background: $white;

            .product {
                padding: 15px;
                align-items: center;
                border-bottom: 1px solid $light-gray;
                position: relative;
                font-weight: $font-light;

                .delete {
                    height: 27px;
                    width: 27px;
                    top: 50%;
                    right: 15px;
                    transform: translateY(-50%);
                    margin-left: auto;
                    position: absolute;
                    @extend .delete--icon;

                    @include mobile {
                        right: 0;
                    }
                }

                &.total {
                    font-weight: $font-bold;
                    font-size: 20px;
                }

                &:last-child {
                    border-bottom: 0;
                }

                .unitprice {
                    @include mobile {
                        font-size: 17px;
                        font-weight: $font-bold;
                    }
                }

                .details-row {
                    align-items: center;

                    .image {
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        img {
                            max-height: 5em;
                            width: auto;
                            max-width: initial;

                            @include mobile {
                                max-height: 100px;
                                width: auto;
                            }
                        }
                    }
                }

                .quantity {
                    font-size: 14px;

                    input {
                        max-width: 50px;
                        margin: 0 10px;
                        text-align: center;
                        padding: 5px 0;
                        font-size: 16px;
                    }

                    @include mobile {
                        margin: 15px 0;
                    }
                }

                @include mobile {
                    padding-right: 0;
                    padding-left: 0;
                }
            }
        }

        .table-foot {
            background-color: $light-gray;
            padding: 15px 0;
            font-size: 20px;
            font-weight: $font-bold;

            .total {
                display: flex;
                align-items: center;

                .title {
                    @include tablet {
                        padding-left: 30px;
                    }
                }
            }

            .leasing_net {
                align-items: center;
                display: flex;
                justify-content: center;
                font-size: 16px;
                margin-bottom: 10px;

                .info {
                    height: 30px;
                    width: 30px;
                }
            }

            .price {
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .calculator--icon {
                margin-right: 5px;
                margin-left: -35px;
            }
        }

        .table-additional {
            display: flex;
            justify-content: flex-end;

            .calculator-saving-layer {
                margin-top: 5px;

                .button-layer {
                    margin-bottom: 0;
                }

                @include mobile {
                    font-size: 14px;
                }
            }
        }

        .quantity,
        .unitprice,
        .price {
            text-align: center;
        }
    }

    .package {
        .header {
            background: $gray-nav;

            h3 {
                padding-left: 15px;
            }
        }
    }
}

.aimeos-container {
    .checkout-steps {
        display: none;
    }
}
