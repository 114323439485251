.catalog-detail-image .bu-image-single {
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    background-color: #FFFFFF;
    display: inline-block;
    position: relative;
    overflow: hidden;
    height: 533px;
    width: 100%;
}

.catalog-detail-image .bu-image-single .media-item {
    background-color: #FFFFFF;
    background-image: url(../../../images/progress.gif);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    position: relative;
    height: 533px;
    width: 100%;
}

.catalog-detail-image .bu-image-single .media-item .item {
    transform: translate(-50%, -50%);
    position: relative;
    max-height: 100%;
    max-width: 100%;
    top: 50%;
    left: 50%;
}

.article-price {
    display: inline-block;

    .price-text {
        line-height: normal;

        .value {
            font-weight: 400;
            vertical-align: inherit;
            font-family: Neuzeit Bold, sans-serif;
            font-size: 46px;
            margin: 0;
            display: inline;
            vertical-align: middle;
            color: $dark-gray;
        }
    }
}
.catalog-detail-image .bu-image-single .media-item {
    display: none;
}

.catalog-detail-image .bu-image-single .media-item.selected {
    display: block;
}

.catalog-detail-image .thumbs .bu-item {
    display: inline-block;
    height: 105px;
    width: 80px;
    opacity: 0.7;
    margin: 0;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.catalog-detail-image .thumbs .bu-item:hover {
    opacity: 1;
}
