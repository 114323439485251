#header-and-body {
    background-color: #ececed;
}

body {
    background-color: $primary-color;
}

.body {
    padding: 25px 0 25px 0;
}

a[href^='tel:'] {
    color: $black;
    @include mobile{
        text-decoration: underline;
    }
    @include tablet {
        text-decoration: none;
        pointer-events: none;
    }
}
.flex {
    display: flex;
}

.grid {
    display: grid;
}
