@font-face {
    font-family: 'Source Sans Pro';
    font-style:  normal;
    font-weight: 700;
    src: url("../fonts/source-sans-pro/SourceSansPro-Bold.ttf"),
    url("../fonts/source-sans-pro/SourceSansPro-Bold.ttf");
}

@font-face {
    font-family: 'Source Sans Pro';
    font-style:  normal;
    font-weight: 400;
    src: url("../fonts/source-sans-pro/SourceSansPro-Regular.ttf"),
    url("../fonts/source-sans-pro/SourceSansPro-Regular.ttf");
}

@font-face {
    font-family: 'Source Sans Pro';
    font-style:  normal;
    font-weight: 200;
    src: url("../fonts/source-sans-pro/SourceSansPro-Light.ttf"),
    url("../fonts/source-sans-pro/SourceSansPro-Light.ttf");
}
