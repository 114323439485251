.info-wrapper {
    width: 100%;
    background-color: $white;
    border-radius: 20px;
    margin-top: 30px;
    padding: 20px;

    .eleasataschenrechner {
        width: 250px;
        @include mobile {
            width: 50%;
            margin: 0 auto;
            display: block;
        }
    }

    .info-box-text {
        font-size: 30px;
        width: 100%;
        @include mobile {
            font-size: 15px;
            margin-top: 25px;
            text-align: center;
        }

        .icon {
            width: 110px;
            float: right;
            @include mobile {
                width: 75px;
            }
        }

        span {
            display: block;
            font-family: "Bradford Medium", sans-serif;
        }

        .info-box-text-second {
            padding-left: 30px;
        }

        .info-box-text-third {
            padding-left: 60px;
        }

        .info-box-text-fourth {
            padding-left: 30px;
        }

        .info-box-text-fifth {
            padding-left: 114px;
        }
    }

}

.contact-box-wrapper {
    padding: 20px 50px;
    margin-top: 30px;
    width: 100%;
    background-color: $white;
    border-radius: 20px;

    .kundenservice {
        width: 175px;
        height: 130px;
        fill: none;
        stroke: $black;

        @include mobile {
            width: 120px;
            display: block;
            margin: 0 auto;
            margin-top: 10px;
        }
    }

    .contact-box--text {
        font-size: 30px;
        @include mobile {
            font-size: 16px;
        }

        span {
            display: block;
            font-family: "Bradford Medium", sans-serif;
        }

        .contact-text-second {
            padding-left: 15%;
        }

        .contact-text-third {
            padding-left: 30%;
        }
    }
}

.info-text {
    @include mobile {
        padding: 0 !important;
    }
}
