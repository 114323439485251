.catalog-subnavigation {
    border: 1px solid $gray;

    .sub-navigation-content {
        padding: 20px;

        .sub-navigation-image-container {
            width: 100%;
            display: flex;
            justify-content: center;
            padding-bottom: 5px;

            img {
                width: 100%;
            }
        }

        .sub-navigation-text-container {
            width: 100%;
            display: flex;
            justify-content: center;

            span {
                text-align: center;
            }
        }
    }
}
