.dealer-search--info-box {
    margin-top: 65px;
    font-family: "Neuzeit Regular", sans-serif;
    font-size: 20px;
    margin-bottom: 80px;
    display: flex;
    align-items: center;

    a {
        color: $black;
        text-decoration: underline;
    }

    @include tablet {
        font-size: 23px;
    }

    span {
        display: inline-block;
        padding-top: 10px;
    }

    .dealer-search-info {
        margin-right: 17px;
        display: inline-block;

        @include tablet {
            width: 41px;
            height: 41px;
            min-width: 41px;
        }
    }

    &.desired-bike {
        background-color: $primary-color;
        border-radius: 30px;
        padding: 0.6rem 1rem;
        font-size: 22px;
        color: $white;
        margin: 5rem 0;

        a {
            color: $white;
            white-space: nowrap;
        }

        .dealer-search-info {
            width: 62px;
            height: 60px;
            min-width: 60px;
        }
    }
}



.dealer-search--how-to-container {
    width: 100%;
    padding-bottom: 80px;

    .dealer-search--how-to-headline {
        font-family: "Bradford Medium", sans-serif;
        font-size: 39px;
        padding-bottom: 40px;
        padding-top: 80px;
    }

    .dealer-search--how-to-body {
        font-size: 13px;
        margin: 0 auto;

        .customer {
            font-size: 11px;
            text-align: center;
            margin-right: 0;
            display: inline-block;

            &.customer-budget {
                margin-right: 0;
            }
        }

        .dealer-search--how-to-item {
            text-align: center;
            display: flex;
            flex-direction: column;
            padding: 15px;
            min-height: 390px;

            @include mobile {
                padding-bottom: 35px;
                margin-bottom: 40px;
            }

            .how-to-item-inner {
                border: 1px solid $black;
                border-radius: 50px;
                padding: 15px;
                height: 100%;
                display: flex;
                flex-direction: column;
            }

            .head-text {
                text-align: left;
                font-size: 14px;
                font-family: 'Neuzeit Regular', sans-serif;
            }

            .btn-store {
                background-color: $primary-color;
                margin: 30px auto 8px auto;
                border-radius: 20px;
                display: inline-block;

                a {
                    padding: 7px 50px;
                    font-size: 20px;
                    font-family: 'Bradford Medium', sans-serif;
                    color: $white;

                    &:hover {
                        text-decoration: none;
                    }
                }
            }

            .additional-info {
                margin: 15px -15px;
                font-size: 23px;
                font-family: "Neuzeit Bold", sans-serif;
                padding: 15px 0;
                background-color: $white;

                .no-budget {
                    font-size: 16px;
                }
            }

            .icon {
                stroke: $black;
                display: block;
                margin: auto auto 0 auto;
                width: 100%;
                height: 180px;

                &.rennrad {
                    width: 200px;
                }

                &.vertrag {
                    width: 180px;
                    margin: auto auto 0 auto;
                }

                &.smartphonechat {
                    width: 170px;
                }

                &.rechnung {
                    width: 150px;
                }

            }

            .dealer-search--how-to-text {
                text-align: center;
                font-family: "Source Sans Pro", sans-serif;

                .text-block {
                    min-height: 85px;
                }

                .number-circle {
                    border-radius: 50%;
                    background-color: $black;
                    width: 45px;
                    margin: 0 5px;

                    .number {
                        text-align: center;
                        color: $white;
                        display: inline-block;
                        font-size: 30px;
                        font-family: 'Bradford Medium', sans-serif;
                    }
                }
            }
        }
    }
}
