.catalog-filter-attribute {
    margin: 0;
    background-color: $white;
    padding: 2em 25px;

    .attribute-lists {
        .attr-list {
            border: 1px solid $primary-color;
            border-radius: 0 0 10px 10px;
            top: -10px;
            position: relative;
            margin: 0;
            left: -1px;
            border-top: 0;
            padding: 15px 0 5px 0;
            font-size: 14px;
            background: $white;
        }

        .attr-item {
            padding: 0 !important;

            .attr-name {
                width: 100%;
                padding: 10px 15px;
                margin-bottom: 3px;

                &:before {
                    content: unset;
                }

                &:hover {
                    color: $white;
                    background: $primary-color;
                }
            }

            &:checked {
                + .attr-name {
                    background: $primary-color;
                    color: $white;

                    &:before {
                        content: unset;
                    }
                }
            }
        }
    }

    fieldset {
        width: auto;
        padding: 0;
        margin-right: 5px;

        legend {
            background: $white;
            color: $primary-color;
            font-size: 14px;
            padding: 7px 20px;
            border: 1px solid $primary-color;
            border-radius: 10px;

            svg {
                width: 20px;
                height: 20px;
                display: inline-block;
            }

            &:after {
                content: unset;
            }

            &:hover {
                background: $white;
            }
        }

        @include mobile {
            width: 100%;
            margin-bottom: 5px;
        }
    }
}
